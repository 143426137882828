import React, { useContext } from 'react';
import useStyles from './style';
import { Button, CircularProgress } from '@mui/material';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AlertTitle, Alert } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import { getBuildingPhotoAction } from '../actions/buildings_action';
import { t } from 'i18next';

const BuildingDisplay = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setCurrentDisplay } = useContext(GlobalContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('960'), { noSsr: true });

    const [searchParams, setSearchParams] = useSearchParams();
    const buildingId = searchParams.get('buildingId');

    const [showMarker, setShowMarker] = React.useState(null);

    const buildingLevelsSelector = useSelector(state => state.buildingLevelsState);
    const { loading, buildingLevelsData } = buildingLevelsSelector;

    const [buildingPhoto, setBuildingPhoto] = React.useState(null);
    const [buildingPhotoMobile, setBuildingPhotoMobile] = React.useState(null);

    const photo = !isMobile ? buildingPhoto : buildingPhotoMobile;

    const buildingPhotoSelector = useSelector(state => state.buildingPhotoState);
    const { loading: loading_building_photo, buildingPhotoData } = buildingPhotoSelector;

    React.useEffect(() => {
        if (buildingId) {
            dispatch(getBuildingPhotoAction(buildingId));
        }
    }, [buildingId]);

    React.useEffect(() => {
        if (typeof buildingPhotoData !== 'undefined') {
            if (buildingPhotoData.success) {
                setBuildingPhoto(buildingPhotoData.buildingPhoto);
                setBuildingPhotoMobile(buildingPhotoData.buildingPhotoMobile);
            }
        }
    }, [buildingPhotoData]);

    const SvgPolygons = () => {
        return (
            <>
                <svg
                    //viewBox="0 0 1133 643"
                    //viewBox={isMobile ? "0 0 1133 640" : "0 0 1133 643"}
                    viewBox={isMobile ? "0 0 1080 1080" : "0 0 1133 643"}
                    //preserveAspectRatio="xMidYMax slice"
                    className={classes.svg}
                >
                    {
                        buildingLevelsData.levels.map((level, index) => {
                            return (
                                <polygon
                                    onClick={(e) => {
                                        e.preventDefault()
                                        navigate(`?buildingId=${buildingId}&levelId=${level.id}`);
                                        localStorage.setItem('currentDisplay', JSON.stringify(2));
                                        setCurrentDisplay(2);
                                    }}
                                    // onTouchStart={() => {
                                    //     navigate(`?buildingId=${buildingId}&levelId=${level.id}`);
                                    //     localStorage.setItem('currentDisplay', JSON.stringify(2));
                                    //     setCurrentDisplay(2);
                                    // }}
                                    onMouseEnter={!isMobile ? () => {
                                        setShowMarker(index);
                                    } : null}
                                    onMouseLeave={!isMobile ? () => {
                                        setShowMarker(null);
                                    } : null}
                                    className={classes.polygon}
                                    key={index}
                                    points={ isMobile ? getPolygonString(level.polygon_mobile) : getPolygonString(level.polygon) }
                                    style={{ fill: 'rgba(129,215,66,0.3)', strokeWidth: '1', cursor : 'pointer' }}
                                />
                            )
                        })
                    }
                    {
                        isMobile
                            ?
                            (
                                buildingLevelsData.levels.map((level, index) => {
                                    return (
                                        level.polygon_mobile 
                                        ?
                                        [<rect
                                            key={index}
                                            fill='#4B77BE'
                                            x={level.polygon_mobile[2]?.x}
                                            y={level.polygon_mobile[2]?.y}
                                            width="140"
                                            height="60" />,
                                        <text
                                            className={classes.textSvg}
                                            x={level.polygon_mobile[2]?.x + 70}
                                            y={level.polygon_mobile[2]?.y + 37}
                                            color='white'
                                            dominant-baseline="middle"
                                            text-anchor="middle">
                                            {level.level_number}. &nbsp;&nbsp;
                                            {level.availableApartments}
                                        </text>]
                                        :
                                        null
                                    )
                                })
                            )
                            :
                            null
                    }
                </svg>
                {
                    !isMobile
                        ?
                        (
                            buildingLevelsData.levels.map((level, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            left: `${level.polygon[2].x + 10}px`,
                                            top: `${level.polygon[2].y - 30}px`,
                                            display: showMarker === index ? 'grid' : 'none'
                                        }}
                                        className={classes.levelMarker}>
                                        <div className={classes.levelNumber}>
                                            <Typography variant='h4'>{level.level_number}</Typography>
                                            <Typography variant='caption'> {t("Sprat")} </Typography>
                                        </div>
                                        <div className={classes.availableApartments}>
                                            <Typography variant='h4'>{level.availableApartments}</Typography>
                                            <Typography variant='caption'>{t("Dostupno")}</Typography>
                                        </div>
                                    </div>
                                )
                            })
                        )
                        :
                        null
                }
            </>
        )
    };

    const getPolygonString = (polygon) => {
        let polygonString = '';
        for (let i = 0; i < polygon.length; i++) {
            const temp = polygon[i].x.toString() + ',' + polygon[i].y.toString() + ' ';
            polygonString += temp;
        };
        return polygonString;
    };

    const ShowLoading = () => {
        return (
            <div className={classes.loading}>
                <CircularProgress color='warning' thickness={4} size={300} />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div className={classes.error}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje spratova
                </Alert>
            </div>
        )
    };

    return (
        <div
            style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + photo})`, filter: `blur( ${loading ? '2px' : '0px'} )` }}
            className={classes.buildingDisplayDiv}>
            {
                loading
                    ?
                    <ShowLoading />
                    :
                    null
            }
            {
                typeof buildingLevelsData !== 'undefined'
                    ?
                    buildingLevelsData.success
                        ?
                        [
                            <SvgPolygons />,
                            // <div className={classes.buildingName}>
                            //     <Typography variant={isMobile ? 'h5' : 'h4'}>
                            //         {buildingLevelsData.buildingName}
                            //     </Typography>
                            //     <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>
                            //         Zgrada
                            //     </Typography>
                            // </div>,
                            <div className={classes.backDiv}>
                                <Button
                                    onClick={() => {
                                        navigate('/');
                                        localStorage.setItem('currentDisplay', JSON.stringify(0));
                                        setCurrentDisplay(0);
                                    }}
                                    style={{ backgroundColor: 'white', color: 'black' }}
                                    variant='contained'
                                    fullWidth
                                    size='large'
                                    disableElevation>
                                    {isMobile ? t("Nazad") : t("Nazad na kompleks")}
                                </Button>
                            </div>
                        ]
                        :
                        <ShowError />
                    :
                    null
            }
        </div>
    );
};

export default BuildingDisplay;