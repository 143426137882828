import * as api from '../api/api';

export const addNewLevelAction = (levelData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_LEVEL' });

        const { data } = await api.addNewLevel(levelData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_LEVEL',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new level: ', error.message);
        dispatch({
            type: 'ERROR_ADD_NEW_LEVEL',
            payload: error.response.data
        });
    }
};

export const getBuildingLevelsAction = (buildingId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_BUILDING_LEVELS' });

        const { data } = await api.getBuildingLevels(buildingId);

        dispatch({
            type: 'SUCCESS_GET_BUILDING_LEVELS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting building levels: ', error.message);
        dispatch({
            type: 'ERROR_GET_BUILDING_LEVELS',
            payload: error.response.data
        });
    }
};

export const updateBuildingLevelAction = (levelData) => async (dispatch) => {
    try {

        const { data } = await api.updateLevel(levelData);

        dispatch({
            type: 'SUCCESS_GET_BUILDING_LEVELS',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating building level: ', error.message);
        dispatch({
            type: 'ERROR_GET_BUILDING_LEVELS',
            payload: error.response.data
        });
    }
}

export const deleteBuildingLevelAction = (levelData) => async (dispatch) => {
    try {

        const { data } = await api.deleteLevel(levelData);

        dispatch({
            type: 'SUCCESS_GET_BUILDING_LEVELS',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating building level: ', error.message);
        dispatch({
            type: 'ERROR_GET_BUILDING_LEVELS',
            payload: error.response.data
        });
    }
}

export const getLevelPlanPhotoAction = (levelId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_LEVEL_PLAN_PHOTO' });

        const { data } = await api.getLevelPlanPhoto(levelId);

        dispatch({
            type: 'SUCCESS_GET_LEVEL_PLAN_PHOTO',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting level plan photo: ', error.message);
        dispatch({
            type: 'ERROR_GET_LEVEL_PLAN_PHOTO',
            payload: error.response.data
        });
    }
};