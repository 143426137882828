import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkJwtTokenAction, emptyJwtTokenAction, checkRegularAdminAction } from './actions/admins_action';
import { useDispatch, useSelector } from 'react-redux';

const ProtectedRouteRegularAdmin = ({ children, type }) => {

    const dispatch = useDispatch();

    const jwtTokenCheckSelectorData = useSelector(state => state.checkRegularAdminState);
    const { regularAdminCheck } = jwtTokenCheckSelectorData;

    React.useEffect(() => {
         // dispatch(emptyJwtTokenAction());
        // dispatch(checkJwtTokenAction());
        dispatch(checkRegularAdminAction());
    }, []);

    return (

        typeof regularAdminCheck !== 'undefined'
            ?
            (
                regularAdminCheck.success
                    ?
                    children
                    :
                    <Navigate to='/login' />
            )
            :
            null
    );
};

export default ProtectedRouteRegularAdmin;
