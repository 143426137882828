import * as api from '../api/api';

export const addNewBuildingAction = (buildingData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_BUILDING' });

        const { data } = await api.addNewBuilding(buildingData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_BUILDING',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new building ', error.message);
        dispatch({
            type: "ERROR_ADD_NEW_BUILDING",
            payload: error.response.data
        });
    }
};


export const getBuildingsAction = () => async (dispatch) => {
    try {

        dispatch({ type: "REQUEST_GET_BUILDINGS" });

        const { data } = await api.getBuildings();

        dispatch({
            type: "SUCCESS_GET_BUILDINGS",
            payload: data
        });

    } catch (error) {
        console.log('Error in getting buildings: ', error.message);
        dispatch({
            type: "ERROR_GET_BUILDINGS",
            payload: error.response.data
        });
    }
};

export const updateBuildingAction = (updateData) => async (dispatch) => {
    try {

        const { data } = await api.updateBuilding(updateData);

        dispatch({
            type: "SUCCESS_GET_BUILDINGS",
            payload: data
        });

    } catch (error) {
        console.log('Error in updating building: ', error.message);
        dispatch({
            type: "ERROR_GET_BUILDINGS",
            payload: error.response.data
        });
    }
};

export const deleteBuildingAction = (deleteData) => async (dispatch) => {
    try {

        const { data } = await api.deleteBuilding(deleteData);

        dispatch({
            type: "SUCCESS_GET_BUILDINGS",
            payload: data
        });

    } catch (error) {
        console.log('Error in updating building: ', error.message);
        dispatch({
            type: "ERROR_GET_BUILDINGS",
            payload: error.response.data
        });
    }
};

export const getBuildingPhotoAction = (buildingId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_BUILDING_PHOTO' });

        const { data } = await api.getBuildingPhoto(buildingId);

        dispatch({
            type: 'SUCCESS_GET_BUILDING_PHOTO',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting level plan photo: ', error.message);
        dispatch({
            type: 'ERROR_GET_BUILDING_PHOTO',
            payload: error.response.data
        });
    }
};