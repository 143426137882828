import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    levelDisplayDiv: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '643px',
        width: '1133px',
        overflow: 'hidden',
        backgroundSize: 'cover',
        [theme.breakpoints.down('960')]: {
            borderBottom: '2px solid #D2D7D3',
            backgroundSize: 'cover',
            order : '1',
            height : '100%',
            width: '100%',
        }
    },
    svg: {
        [theme.breakpoints.down('xs')]: {
            //border: '2px solid green',
            width: '100%',
            height: '100%',
            transform: 'scale(1.02)'
        }
    },
    textSvg : {
        fill : 'black',
        cursor : 'pointer'
    },
    loading: {
        position: 'absolute',
        left: '50%',
        top: '26%',
        transform: "translate(-50%, 0)",
        zIndex: '50'
    },
    polygon: {
        cursor: 'pointer',
        '&:hover': {
            fill: 'rgba(30,93,188,0.31) !important'
        }
    },
    buildingAndLevel: {
        position: 'absolute',
        top: '40px',
        right: '40px',
        display: 'grid',
        gridTemplateColumns: '1fr'
    },
    buildingAndLevelMobile: {
        position: 'absolute',
        bottom: '5px',
        left: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        display : 'none',
    },
    buildingAndLevelSubDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '120px',
        height: '120px',
        border: '1px solid #D2D7D3'
    },
    buildingAndLevelSubDivMobile: {
        "&:nth-child(odd)": {
            borderRight: '0px'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90px',
        height: '60px',
        border: '1px solid #D2D7D3'
    },
    apartmentDetails: {
        position: 'absolute',
        left: '40px',
        top: '110px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    apartmentDetailsCard: {
        //padding : '10px',
        border: '1px solid #D2D7D3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '175px',
        height: '105px',
        // '&:last-child': {
        //     width: '150px'
        // }
    },
    backDiv: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        backgroundColor: 'white',
        color: 'black',
        [theme.breakpoints.down('xs')]: {
            top: '5px',
            left: '5px',
        }
    }
}));

export default useStyles;