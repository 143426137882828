import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableDiv: {
    //border : '3px solid black',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderBottom: '2px solid #D2D7D3',
    }
  },
  table: {
    width: '90%',
    margin: 'auto',
    //border : '2px solid red',
    marginTop: '3%',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      marginBottom: '15px'
    },
    '& .MuiTableCell-root' : {
      padding : '5px 5px 5px 5px'
    }
  },
  tableDialog: {
    borderCollapse: 'collapse',
    width: '100%',
    "& th": {
      fontSize: '14px',
      textAlign: 'left',
      padding: '10px',
      border: '1px solid #EEEEEE',
    },
    "& td": {
      fontSize: '13px',
      textAlign: 'center',
      padding: '10px',
      border: '1px solid #EEEEEE',
    },
  },

}));

export default useStyles;