import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    apartmentDiv: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%',
        backgroundPosition: 'top center',
        // height: '643px !important',
        // width: '1133px',
        overflow: 'hidden',
        height: '100%',
        [theme.breakpoints.down('960')]: {
            width: '100%',
            minHeight: '100vh',
            backgroundSize: '100%',
            backgroundPosition: 'center center',
            marginBottom : '20px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minHeight: '100%',
            backgroundPosition: 'center center',
            backgroundSize: '100%',
            marginBottom : '20px'
        }
    },
    sold : {
        position : 'absolute',
        top : '180px',
        left : '38%',
        [theme.breakpoints.down('xs')]: {
            top : '80px',
            left : '27%'
        }
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buildingAndLevel: {
        position: 'absolute',
        top: '40px',
        right: '40px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.down('xs')]: {
            display : 'none'
        }

    },
    buildingAndLevelSubDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '150px',
        height: '120px',
        border: '1px solid #D2D7D3',
        "&:nth-child(odd)": {
            borderBottom : '0px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '75px',
            height: '62px',
        }
    },
    apartmentDetails: {
        position: 'absolute',
        left: '40px',
        top: '12%',
        display : 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            //display : 'grid',
            // gridTemplateColumns : 'repeat(3,1fr)',
            // top : '35vh',
            // //border : '1px solid red',
            // width : '100%',
            // left : '0%',
            // height : '160px',
            display : 'none',
        }
    },
    apartmentDetailsMobile : {
        display : 'grid',
        gridTemplateColumns : 'repeat(3,1fr)',  
        //marginTop : '30px'

    },
    apartmentDetailsCard: {
        //padding : '10px',
        border: '1px solid #D2D7D3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '140px',
        height: '100px',
        // '&:last-child': {
        //     width: '180px',
        //     [theme.breakpoints.down('xs')]: {
        //         width : '95px'
        //     }
        // },
        [theme.breakpoints.down('xs')]: {
            width : '100%',
            height : '100px'
        }
    },
    apartmentDetailsCardMobile: {
        //padding : '10px',
        border: '1px solid #D2D7D3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '70px',
    },
    photosAndPdf: {
        //border : '3px solid black',
        width : '65vw',
        position: 'absolute',
        padding: '3px',
        left: '20%',
        bottom: '20%',
        display: 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        //gap: '20px',
        [theme.breakpoints.down('xs')]: {
            display : 'none',  
        }
    },
    photosAndPdfMobile : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-evenly',
        alignItems : 'center',
        flexWrap : 'wrap',
        marginTop : '25px',
        gap : '15px',
        [theme.breakpoints.down('367')]: {
            justifyContent : 'center',
            gap : '6px'
            // display : 'grid',
            // gridTemplateColumns : '1fr 1fr 1fr',
            // gap : '10px',
        }
    },
    oneDiv: {
        //border : '2px solid red',
        display: 'flex',
        flexDirection: 'row',
        //justifyContent : 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            margin : '10px'
        }
    },
    divCircle: {
        //border : '1px solid green',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '50px',
        border: '1px solid #D2D7D3',
        borderRadius: '50%',
        marginRight: '20px',
        transition: '0.6s',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0074b9',
            color: 'white'
        },
        [theme.breakpoints.down('xs')]: {
            width : '30px',
            height : '30px',
            marginRight : '5px',
            border : '1px solid transparent'
        }
    },
    divCirclePdf: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100px',
        height: '100px',
        border: '1px solid #D2D7D3',
        marginRight: '20px',
        transition: '0.6s',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0074b9',
            color: 'white'
        },
        [theme.breakpoints.down('xs')]: {
            width : '40px',
            height : '40px',
            marginRight : '0px',
            border: 'none',
        }
    },
    galleryCarousel: {

    },
    galleryPhoto: {

    },
    backDiv: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        backgroundColor: 'white',
        color: 'black',
        [theme.breakpoints.down('xs')]: {
            top: '5px',
            left: '5px'
        }
    },
    orbitalDialog : {
        '& 	.MuiDialog-paper' : {
            alignItems : 'center'
        },
        '& .MuiDialog-container' : {
            alignItems : 'center'
        }
    }
}));

export default useStyles;