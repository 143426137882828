import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

//ADMINS
export const loginAdmin = (data) => API.post('/admins/login-admin', data);
export const checkJwtToken = () => API.post('/admins/check-jwt-token');
export const logout = () => API.post('/admins/logout');
export const checkSuperAdmin = () => API.post('/admins/check-super-admin');
export const checkRegularAdmin = () => API.post('/admins/check-regular-admin');
export const getHeaderData = () => API.get('/admins/get-header-data');
export const updateHeaderData = (headerData) => API.post('/admins/update-header-data',headerData);
export const getComplexPhoto = () => API.get('/admins/get-complex-photo');

//BUILDINGS
export const addNewBuilding = (data) => API.post('/buildings/add-new-building',data);
export const getBuildingPhoto = (buildingId) => API.get(`/buildings/get-building-photo/${buildingId}`);
export const updateBuilding = (data) => API.post('/buildings/update-building',data);
export const getBuildings = () => API.get('/buildings/get-buildings');
export const deleteBuilding = (data) => API.post('/buildings/delete-building',data);

//LEVELS
export const addNewLevel = (data) => API.post('/levels/add-new-level',data);
export const getBuildingLevels = (buildingId) => API.get(`/levels/get-building-levels?buildingId=${buildingId}`);
export const getLevelPlanPhoto = (levelId) => API.get(`/levels/get-level-plan-photo/${levelId}`);
export const updateLevel = (data) => API.post('/levels/update-level',data);
export const deleteLevel = (data) => API.post('/levels/delete-level',data);

//APARTMENTS
export const addNewApartment = (data) => API.post('/apartments/add-new-apartment',data);
export const getApartmentsForLevel = (buildingId,levelId) => API.get(`/apartments/get-apartments-for-level?levelId=${levelId}&buildingId=${buildingId}`);
export const getApartment = (apartmentId) => API.get(`/apartments/get-apartment/${apartmentId}`);
export const sendPlanToEmail = (data) => API.post('/apartments/send-plan-to-email',data);
export const sendQuestion = (data) => API.post('/apartments/send-question',data);
export const getParameters = () => API.get('/apartments/get-parameters');
export const getDataByParameters = (data) => API.post('/apartments/get-data-by-parameters',data);
export const getApartmentsForRegularAdmin = () => API.get('/apartments/apartments-for-regular-admin');
export const changeApartmentStatus = (data) => API.post('/apartments/change-apartment-status',data);
export const changeApartmentPrice = (data) => API.post('/apartments/change-apartment-price',data);
export const searchApartments = (searchQuery) => API.get(`/apartments/search-apartments?searchQuery=${searchQuery}`);
export const getTableApartments = () => API.get('/apartments/get-apartments-table');
export const getApartmentToEdit = (apartmentId) => API.get(`/apartments/get-apartment-to-edit?apartmentId=${apartmentId}`);
export const updateApartment = (data) => API.post('/apartments/update-apartment',data);
export const deleteApartment = (data) => API.post('/apartments/delete-apartment',data);