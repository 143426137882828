import * as api from '../api/api';

export const addNewApartmentAction = (newApartmentData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_ADD_NEW_APARTMENT' });

        const { data } = await api.addNewApartment(newApartmentData);

        dispatch({
            type: 'SUCCESS_ADD_NEW_APARTMENT',
            payload: data
        });

    } catch (error) {
        console.log('Error in adding new apartment: ', error.message);
        dispatch({
            type: 'ERROR_ADD_NEW_APARTMENT',
            payload: error.response.data
        });
    }
};

export const getApartmentsForLevelAction = (buildingId, levelId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_APARTMENTS_FOR_LEVEL' });

        const { data } = await api.getApartmentsForLevel(buildingId, levelId);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_LEVEL',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting apartments for level. ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_LEVEL',
            payload: error.response.data
        })
    }
};

export const getApartmentAction = (apartmentId) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_APARTMENT' });

        const { data } = await api.getApartment(apartmentId);

        dispatch({
            type: 'SUCCESS_GET_APARTMENT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting apartment: ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENT',
            payload: error.response.data
        })
    }
};

export const sendPlanToEmailAction = (apartmentData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SEND_PLAN_TO_EMAIL' });

        const { data } = await api.sendPlanToEmail(apartmentData);

        dispatch({
            type: 'SUCCESS_SEND_PLAN_TO_EMAIL',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending plan to email: ', error.message);
        dispatch({
            type: 'ERROR_SEND_PLAN_TO_EMAIL',
            payload: error.response.data
        })
    }
};

export const sendQuestionAction = (questionData) => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_SEND_QUESTION' });

        const { data } = await api.sendQuestion(questionData);

        dispatch({
            type: 'SUCCESS_SEND_QUESTION',
            payload: data
        });

    } catch (error) {
        console.log('Error in sending question: ', error.message);
        dispatch({
            type: 'ERROR_SEND_QUESTION',
            payload: error.response.data
        })
    }
};

export const getParametersAction = () => async (dispatch) => {
    try {

        dispatch({ type: 'REQUEST_GET_PARAMETERS' });

        const { data } = await api.getParameters();

        dispatch({
            type: 'SUCCESS_GET_PARAMETERS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting parameters: ', error.message);
        dispatch({
            type: 'ERROR_GET_PARAMETERS',
            payload: error.response.data
        });
    };
};


export const getDataByParametersAction = (parametersData) => async (dispatch) => {

    switch (parametersData.dataType) {
        case 0: getComplexByParameters(parametersData, dispatch); break;
        case 1: getBuildingByParameters(parametersData, dispatch); break;
        case 2: getLevelApartmentsByParameters(parametersData, dispatch); break;
        case 4: getTableApartmentsByParameters(parametersData, dispatch); break;
        default: getComplexByParameters(parametersData, dispatch); break;
    };

};

const getComplexByParameters = async (parametersData, dispatch) => {
    try {

        const { data } = await api.getDataByParameters(parametersData);

        dispatch({
            type: "SUCCESS_GET_BUILDINGS",
            payload: data
        });

    } catch (error) {
        console.log('Error in getting buildings by parameters: ', error.message);
        dispatch({
            type: "ERROR_GET_BUILDINGS",
            payload: error.response.data
        });
    }
};

const getBuildingByParameters = async (parametersData, dispatch) => {
    try {

        const { data } = await api.getDataByParameters(parametersData);

        dispatch({
            type: 'SUCCESS_GET_BUILDING_LEVELS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting building levels: ', error.message);
        dispatch({
            type: 'ERROR_GET_BUILDING_LEVELS',
            payload: error.response.data
        });
    }
};

const getLevelApartmentsByParameters = async (parametersData, dispatch) => {
    try {

        const { data } = await api.getDataByParameters(parametersData);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_LEVEL',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting apartments for level. ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_LEVEL',
            payload: error.response.data
        })
    }
};

export const getApartmentsForRegularAdminAction = () => async (dispatch) => {
    try {

        dispatch({
            type: 'REQUEST_GET_APARTMENTS_FOR_REGULAR_ADMIN',
        });

        const { data } = await api.getApartmentsForRegularAdmin();

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting apartments for regular admin. ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: error.response.data
        })
    }
};

export const changeApartmentStatusAction = (apartmentId) => async (dispatch) => {
    try {

        const { data } = await api.changeApartmentStatus(apartmentId);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in changing apartment status. ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: error.response.data
        })
    }
};

export const changeApartmentPriceAction = (newPriceData) => async (dispatch) => {
    try {

        const { data } = await api.changeApartmentPrice(newPriceData);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in changing apartment price. ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: error.response.data
        })
    }
};

export const searchApartmentsAction = (searchQuery) => async (dispatch) => {
    try {

        const { data } = await api.searchApartments(searchQuery);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in searching apartments', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_REGULAR_ADMIN',
            payload: error.response.data
        });
    }
};

export const getTableApartmentsAction = () => async (dispatch) => {
    try {

        dispatch({ type : 'REQUEST_GET_TABLE_APARTMENTS' });

        const { data } = await api.getTableApartments();

        dispatch({
            type: 'SUCCESS_GET_TABLE_APARTMENTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting table apartments', error.message);
        dispatch({
            type: 'ERROR_GET_TABLE_APARTMENTS',
            payload: error.response.data
        });
    }
};

const getTableApartmentsByParameters = async (parametersData, dispatch) => {
    try {

        const { data } = await api.getDataByParameters(parametersData);

        dispatch({
            type: 'SUCCESS_GET_TABLE_APARTMENTS',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting table apartments by parameters', error.message);
        dispatch({
            type: 'ERROR_GET_TABLE_APARTMENTS',
            payload: error.response.data
        });
    }
};

export const getApartmentToEditAction = (apartmentId) => async (dispatch) => {
    try {

        dispatch({ type : 'REQUEST_GET_APARTMENT_TO_EDIT' });

        const { data } = await api.getApartmentToEdit(apartmentId);

        dispatch({
            type: 'SUCCESS_GET_APARTMENT_TO_EDIT',
            payload: data
        });

    } catch (error) {
        console.log('Error in getting apartment to edit', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENT_TO_EDIT',
            payload: error.response.data
        });
    }
};

export const updateApartmentAction = (updateData) => async (dispatch) => {
    try {

        const { data } = await api.updateApartment(updateData);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_LEVEL',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating apartment: ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_LEVEL',
            payload: error.response.data
        })
    }
};

export const deleteApartmentAction = (updateData) => async (dispatch) => {
    try {

        const { data } = await api.deleteApartment(updateData);

        dispatch({
            type: 'SUCCESS_GET_APARTMENTS_FOR_LEVEL',
            payload: data
        });

    } catch (error) {
        console.log('Error in updating apartment: ', error.message);
        dispatch({
            type: 'ERROR_GET_APARTMENTS_FOR_LEVEL',
            payload: error.response.data
        })
    }
};