import React, { useContext } from 'react';
import useStyles from './style';
import { Button, CircularProgress, IconButton, Grid, Select, MenuItem, FormControl, TextField, InputLabel } from '@mui/material';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AlertTitle, Alert } from '@mui/material';
import zgrada from '../assets/photos/zgrada.jpg';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildingLevelsAction } from '../actions/levels_action';
import { Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import noPhoto from '../assets/photos/noPhoto.jpeg';
import EditLevelPolygonDialog from './EditLevelPolygonDialog';
import { updateBuildingLevelAction } from '../actions/levels_action';
import DeleteDialog from './DeleteDialog';
import { getBuildingPhotoAction } from '../actions/buildings_action';

const initialLevelData = {
  id: '',
  numOfApartments: '',
  level_number: '',
  building: '',
  plan_photo: '',
  polygon: [],
  polygonMobile: [],
  newPlanPhotoName: ''
};

const EditBuildingLevels = ({ setCurrentSection }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showEditLevelPolygon, setShowEditLevelPolygon] = React.useState(false);

  const [showDeletionDialog, setShowDeletionDialog] = React.useState(false);
  const [deletionDialogData, setDeletionDialogData] = React.useState({});

  const [previewPhoto, setPreviewPhoto] = React.useState(false);

  const [levelData, setLevelData] = React.useState(initialLevelData);

  const { setCurrentDisplay } = useContext(GlobalContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const [searchParams, setSearchParams] = useSearchParams();
  const buildingId = searchParams.get('buildingId');

  const [levelPicked, setLevelPicked] = React.useState(false);

  const [buildingNames, setBuildingNames] = React.useState([]);

  const buildingLevelsSelector = useSelector(state => state.buildingLevelsState);
  const { loading, buildingLevelsData } = buildingLevelsSelector;

  const [buildingPhoto, setBuildingPhoto] = React.useState(null);
  const [buildingPhotoMobile, setBuildingPhotoMobile] = React.useState(null);

  const [buildingPhotoType, setBuildingPhotoType] = React.useState(null);

  const buildingPhotoSelector = useSelector(state => state.buildingPhotoState);
  const { loading: loading_building_photo, buildingPhotoData } = buildingPhotoSelector;

  React.useEffect(() => {
    if (buildingId) {
      dispatch(getBuildingPhotoAction(buildingId));
      dispatch(getBuildingLevelsAction(buildingId));
    }
  }, [buildingId]);

  React.useEffect(() => {
    if (typeof buildingPhotoData !== 'undefined') {
      if (buildingPhotoData.success) {
        setBuildingPhoto(buildingPhotoData.buildingPhoto);
        setBuildingPhotoMobile(buildingPhotoData.buildingPhotoMobile);
      }
    }
  }, [buildingPhotoData]);

  React.useEffect(() => {
    const getBuildingNames = async () => {
      try {
        const api_url = process.env.REACT_APP_API_URL;
        const { data } = await axios({
          method: 'GET',
          url: `${api_url}/buildings/building-names`,
          responseType: 'json'
        });
        setBuildingNames([...data.buildingNamesData]);
      } catch (error) {
        console.log(error);
      }
    };
    getBuildingNames();
  }, []);

  const handleEditLevel = (e) => {
    e.preventDefault();
    dispatch(updateBuildingLevelAction({
      id: levelData.id,
      building: levelData.building,
      num_of_apartments: levelData.numOfApartments,
      level_number: levelData.level_number,
      polygon: levelData.polygon,
      polygonMobile: levelData.polygonMobile,
      newPlanPhotoName: levelData.newPlanPhotoName,
      plan_photo: levelData.plan_photo
    }));

  };

  const handleChange = (e) => {
    setLevelData({
      ...levelData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = async (e) => {
    if (e.target.files) {
      setLevelData({
        ...levelData,
        plan_photo: await readFileAsBase64(e.target.files[0]),
        newPlanPhotoName: e.target.files[0].name
      });
    }
  };

  const getNumberOfLevels = () => {
    const building = buildingNames.find(building => building.id === levelData.building);
    return building.num_of_levels;
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      }
    });
  };

  const SvgPolygons = () => {
    return (
      <>
        <svg
          // height={643}
          // width={1133}
          viewBox="0 0 1133 643"
          //preserveAspectRatio="xMidYMax slice"
          className={classes.svg}
        >
          {
            buildingLevelsData.levels.map((level, index) => {
              return (
                <polygon
                  onClick={() => {
                    setCurrentSection(5);
                    navigate(`?buildingId=${buildingId}&levelId=${level.id}`);
                  }}
                  className={classes.polygon}
                  key={index}
                  points={getPolygonString(level.polygon)}
                  style={{ fill: 'rgba(129,215,66,0.3)', strokeWidth: '1' }}
                />
              )
            })
          }
        </svg>

        {
          buildingLevelsData.levels.map((level, index) => {
            return (
              <div
                key={index}
                style={{
                  left: `${level.polygon[2].x}px`,
                  top: `${level.polygon[2].y}px`
                }}
                className={classes.levelMarker}>
                <IconButton
                  onClick={() => {
                    setLevelData({
                      id: level.id,
                      numOfApartments: level.num_of_apartments,
                      level_number: level.level_number,
                      building: level.building,
                      plan_photo: level.plan_photo,
                      polygon: level.polygon,
                      polygonMobile: level.polygon_mobile
                    });
                    setLevelPicked(true);
                  }}
                  size='small'
                  color='success'>
                  <EditIcon fontSize='small' />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setShowDeletionDialog(true);
                    setDeletionDialogData({
                      type: 1,
                      ...level
                    });
                  }}
                  size='small'
                  color='error'>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </div>
            )
          })
        }

      </>
    )
  };

  const getPolygonString = (polygon) => {
    let polygonString = '';
    for (let i = 0; i < polygon.length; i++) {
      const temp = polygon[i].x.toString() + ',' + polygon[i].y.toString() + ' ';
      polygonString += temp;
    };
    return polygonString;
  };

  const ShowLoading = () => {
    return (
      <div className={classes.loading}>
        <CircularProgress color='warning' thickness={4} size={300} />
      </div>
    )
  };

  const ShowError = () => {
    return (
      <div className={classes.error}>
        <Alert severity="error">
          <AlertTitle>Greška</AlertTitle>
          Neuspješno prikazivanje spratova
        </Alert>
      </div>
    )
  };

  const ShowUpdateError = () => {
    delete buildingLevelsData?.levelUpdate;
    return (
      <div style={{ marginBottom: '20px' }}>
        <Alert severity="error">
          <AlertTitle>Greška</AlertTitle>
          Neuspješno azuriranje sprata
        </Alert>
      </div>
    )
  };

  const ShowUpdateSuccess = () => {
    delete buildingLevelsData?.levelUpdate;
    return (
      <div style={{ marginBottom: '20px' }}>
        <Alert severity="success">
          <AlertTitle>Ok</AlertTitle>
          Sprat azuriran
        </Alert>
      </div>
    )
  };

  return (
    <div className={classes.addBuildingSection}>
      <div className={classes.addBuildingForm}>
        <Typography
          variant='h5'
          style={{ color: 'black', marginBottom: '20px', textAlign: 'center' }} >
          Edit spratova zgrade {buildingLevelsData?.buildingName}
        </Typography>
        {
          typeof buildingLevelsData !== 'undefined'
            ?
            buildingLevelsData.levelUpdate
              ?
              buildingLevelsData.success
                ?
                <ShowUpdateSuccess />
                :
                <ShowUpdateError />
              :
              null
            :
            null
        }
        <form onSubmit={handleEditLevel} className={classes.levelForm}>
          <Grid container spacing={4}>

            <Grid item xs={12} md={12}>
              <TextField
                disabled={!levelPicked}
                fullWidth
                variant="outlined"
                required
                label="Broj stanova"
                name="numOfApartments"
                type="number"
                value={levelData.numOfApartments}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Redni broj sprata</InputLabel>
                <Select
                  disabled={!levelPicked}
                  labelId="demo-simple-select-label"
                  value={levelData.level_number}
                  label="Redni broj sprata"
                  name='level_number'
                  onChange={handleChange}
                >
                  {
                    levelData.building
                      ?
                      Array.from(Array(getNumberOfLevels()).keys()).map((levelNum, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={index + 1}>
                            {index + 1}.
                          </MenuItem>
                        )
                      })
                      :
                      null
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              <Button
                onClick={() => { setPreviewPhoto(true); }}
                disabled={!levelPicked}
                disableElevation
                fullWidth
                size='small'
                variant="contained"
                component="label"
                color='inherit'
              >
                <VisibilityIcon fontSize='small' style={{ marginRight: '5px' }} />
                Pregledaj tlocrt
              </Button>
              <div className={classes.photoPreview} style={{ display: previewPhoto ? 'block' : 'none' }}>
                <img
                  src={levelData.plan_photo ?
                    levelData.newPlanPhotoName ?
                      levelData.plan_photo :
                      process.env.REACT_APP_UPLOADS_PATH + levelData.plan_photo :
                    noPhoto}
                  width='410' />
                <CloseIcon
                  onClick={() => { setPreviewPhoto(false); }}
                  color='error'
                  fontSize='large'
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer'
                  }} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                disabled={!levelPicked}
                disableElevation
                fullWidth
                variant="contained"
                size='small'
                component="label"
                color={levelData?.newPlanPhotoName ? 'success' : 'inherit'}
              >
                {
                  levelData?.newPlanPhotoName
                    ?
                    <DoneAllIcon fontSize='small' style={{ marginRight: '5px' }} />
                    :
                    <AddIcon fontSize='small' style={{ marginRight: '5px' }} />
                }
                Promijeni tlocrt
                <input
                  id='dugme'
                  type="file"
                  onChange={handleImageChange}
                  multiple
                  accept='image/*'
                  hidden
                />
              </Button>
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                disabled={!levelPicked}
                onClick={() => { setBuildingPhotoType('desktop'); setShowEditLevelPolygon(true); }}
                variant='outlined'
              >
                Promijeni poligon
              </Button>
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                disabled={!levelPicked}
                onClick={() => { setBuildingPhotoType('mobile'); setShowEditLevelPolygon(true); }}
                variant='outlined'
              >
                Dodaj poligon za mobilni
              </Button>
            </Grid>

            <Grid item xs={12} md={12}>

              <Button
                fullWidth
                variant='contained'
                color='success'
                type='submit'
              >
                Ok
              </Button>

            </Grid>
          </Grid>
        </form>
      </div>

      <div
        style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + buildingPhoto})`, filter: `blur( ${loading ? '2px' : '0px'} )` }}
        className={classes.addLevelPhoto}>
        {
          loading
            ?
            <ShowLoading />
            :
            null
        }
        {
          typeof buildingLevelsData !== 'undefined'
            ?
            buildingLevelsData.success
              ?
              [
                <SvgPolygons />,
                <div className={classes.buildingName}>
                  <Typography variant={isMobile ? 'h5' : 'h4'}>
                    {buildingLevelsData.buildingName}
                  </Typography>
                  <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>
                    Zgrada
                  </Typography>
                </div>,
                <div className={classes.backDiv}>
                  <Button
                    onClick={() => {
                      navigate('/');
                      localStorage.setItem('currentDisplay', JSON.stringify(0));
                      setCurrentDisplay(0);
                    }}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    variant='contained'
                    fullWidth
                    size='large'
                    disableElevation>
                    nazad na kompleks
                  </Button>
                </div>
              ]
              :
              <ShowError />
            :
            null
        }
      </div>
      <EditLevelPolygonDialog
        open={showEditLevelPolygon}
        setOpen={setShowEditLevelPolygon}
        levelData={levelData}
        setLevelData={setLevelData}
        buildingPhoto={buildingPhoto}
        buildingPhotoMobile={buildingPhotoMobile}
        buildingPhotoType={buildingPhotoType}
        setBuildingPhotoType={setBuildingPhotoType} />
      <DeleteDialog
        open={showDeletionDialog}
        setOpen={setShowDeletionDialog}
        data={deletionDialogData} />
    </div>
  );
};

export default EditBuildingLevels;