import { DialogContent, Dialog, DialogTitle, Grid, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import useStyles from './style';
import EuroIcon from '@mui/icons-material/Euro';

const ApartmentDialog = ({ show, setShow, info, downloadPlanPhoto, getNumOfRooms, t }) => {

    const classes = useStyles();

    const handleClose = () => {
        setShow(false);
    };

    return (
        <Dialog onClose={handleClose} open={show}>
            <DialogTitle>{t("Stan broj")} {info.number}</DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <table className={classes.tableDialog}>
                            <thead>
                                <tr>
                                    <th>{t("Sprat")}</th>
                                    <th>{t("Površina")}</th>
                                    <th>{t("Cijena")}</th>
                                    <th>{t("Tip")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{info.level_number}.</td>
                                    <td>{+info.area} &#13217;</td>
                                    <td>{+info.price} &euro;</td>
                                    <td>{getNumOfRooms(info.type)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography 
                            variant='subtitle1' 
                            color={ info.status ? 'red' : 'green' } 
                            style={{ border: '1px solid', marginBottom : '5px', borderRadius : '4px' }}>
                            {info.status ? t("PRODAT") : t('Dostupno')}
                        </Typography>
                        <Button
                            size='small'
                            onClick={() => { downloadPlanPhoto(info.photo, info.number); }}
                            variant='contained'
                            disableElevation
                            fullWidth>
                            {t("Preuzmi tlocrt stana")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ApartmentDialog;