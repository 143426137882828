import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, MenuItem, FormControl, Select, InputLabel, TextField, Button, LinearProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import zgrada from '../assets/photos/zgrada.jpg';
import zgradaTest from '../assets/photos/zgradaTest.jpg';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import { addNewLevelAction } from '../actions/levels_action';
import { getBuildingPhotoAction } from '../actions/buildings_action';

const levelInitialData = {
    building: '',
    numOfApartments: '',
    level_number: '',
    plan_photo: ''
};

const AddLevelSection = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [levelData, setLevelData] = React.useState(levelInitialData);

    const [polygonLines, setPolygonLines] = React.useState([]);

    const [firstCircle, setFirstCircle] = React.useState(null);

    const [isPathClosed, setIsPathClosed] = React.useState(false);

    const [buildingNames, setBuildingNames] = React.useState([]);

    const canvasRef = React.useRef();

    const addNewLevelSelector = useSelector(state => state.addNewLevelState);
    const { loading, newLevelData } = addNewLevelSelector;

    const [buildingPhoto, setBuildingPhoto] = React.useState(null)

    const buildingPhotoSelector = useSelector(state => state.buildingPhotoState);
    const { loading: loading_building_photo, buildingPhotoData } = buildingPhotoSelector;

    React.useEffect(() => {
        const getBuildingNames = async () => {
            try {
                const api_url = process.env.REACT_APP_API_URL;
                const { data } = await axios({
                    method: 'GET',
                    url: `${api_url}/buildings/building-names`,
                    responseType: 'json'
                });
                setBuildingNames([...data.buildingNamesData]);
            } catch (error) {
                console.log(error);
            }
        };
        getBuildingNames();
    }, []);

    React.useEffect(() => {
        if (levelData.building) {
            dispatch(getBuildingPhotoAction(levelData.building));
        }
    }, [levelData.building]);

    React.useEffect(() => {
        if (typeof buildingPhotoData !== 'undefined') {
            if (buildingPhotoData.success) {
                setBuildingPhoto(buildingPhotoData.buildingPhoto);
            }
        }
    }, [buildingPhotoData]);

    React.useEffect(() => {
        if (polygonLines.length > 0 && !isPathClosed) {
            drawPolygon();
        }
    }, [polygonLines]);

    const handleAddLevel = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('building', levelData.building);
        formData.append('num_of_apartments', levelData.numOfApartments);
        formData.append('level_number', levelData.level_number);
        formData.append('plan_photo', levelData.plan_photo);
        formData.append('polygon', JSON.stringify(polygonLines));
        dispatch(addNewLevelAction(formData));
    };

    const handleCanvasClick = (e) => {

        if (isPathClosed) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const rect = e.target.getBoundingClientRect();

        const context = canvasRef.current.getContext("2d");

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPolygonLines([
            ...polygonLines,
            { x, y }
        ]);

        context.beginPath();
        context.strokeStyle = '#ff0000';
        context.lineWidth = 2;
        context.arc(x, y, 8, 0, 2 * Math.PI);
        context.stroke();

        if (polygonLines.length === 0) {
            setFirstCircle({
                x,
                y,
                r: 12
            });
        }
    };

    const drawPolygon = () => {
        const context = canvasRef.current.getContext("2d");
        context.lineWidth = 2;
        context.fillStyle = 'rgba(129,215,66,0.3)';
        context.strokeStyle = '#ff0000';
        context.beginPath();
        context.moveTo(polygonLines[0].x, polygonLines[0].y);
        for (let index = 1; index < polygonLines.length; index++) {
            context.lineTo(polygonLines[index].x, polygonLines[index].y);
        }
        if (polygonLines.length > 1) {
            const d = Math.sqrt(Math.pow((firstCircle.x - polygonLines[polygonLines.length - 1].x), 2) + Math.pow((firstCircle.y - polygonLines[polygonLines.length - 1].y), 2));
            if (d <= firstCircle.r) {
                setIsPathClosed(true);
                const pl = [...polygonLines];
                pl[pl.length - 1].x = pl[0].x;
                pl[pl.length - 1].y = pl[0].y;
                setPolygonLines([...pl]);
                context.closePath();
                context.fill();
            }
        }
        context.stroke();
    };

    const handleClearCanvas = () => {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setPolygonLines([]);
        setIsPathClosed(false);
    };

    const handleChange = (e) => {
        setLevelData({
            ...levelData,
            [e.target.name]: e.target.value
        });
    };

    const handleImageChange = (e) => {
        if (e.target.files) {
            setLevelData({
                ...levelData,
                plan_photo: e.target.files[0]
            })
        }
    };

    const ShowLoading = () => {
        return (
            <div>
                <LinearProgress color='success' />
            </div>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success">
                <AlertTitle>Uspješno</AlertTitle>
                Novi sprat dodat!
            </Alert>
        );
    };

    const ShowError = () => {
        return (
            <Alert severity="error">
                <AlertTitle>Greška</AlertTitle>
                Neuspješno dodavanje sprata.
            </Alert>
        );
    };

    const getNumberOfLevels = () => {
        const building = buildingNames.find(building => building.id === levelData.building);
        return building.num_of_levels;
    };

    return (
        <div className={classes.addLevelSection}>
            <div className={classes.addLevelForm}>
                <Typography variant='h5' style={{ color: 'black', marginBottom: '20px', textAlign: 'center' }} >Novi sprat</Typography>
                <div className={classes.addBuildingMessage}>
                    {
                        typeof newLevelData !== 'undefined'
                            ?
                            newLevelData.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                    }
                </div>
                <form onSubmit={handleAddLevel} className={classes.levelForm}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Zgrada</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={levelData.building}
                                    label="Zgrada"
                                    name='building'
                                    onChange={handleChange}
                                >
                                    {
                                        buildingNames !== null
                                            ?
                                            buildingNames.map((building, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={building.id}>
                                                        {building.name}
                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Broj stanova"
                                name="numOfApartments"
                                type="number"
                                value={levelData.numOfApartments}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Redni broj sprata</InputLabel>
                                <Select
                                    disabled={!levelData.building}
                                    labelId="demo-simple-select-label"
                                    value={levelData.level_number}
                                    label="Redni broj sprata"
                                    name='level_number'
                                    onChange={handleChange}
                                >
                                    {
                                        levelData.building
                                            ?
                                            Array.from(Array(getNumberOfLevels()).keys()).map((levelNum, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={index + 1}>
                                                        {index + 1}.
                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Button
                                disableElevation
                                fullWidth
                                variant="contained"
                                component="label"
                                color='inherit'
                            >
                                <AddIcon fontSize='small' />
                                Dodaj tlocrt
                                <input
                                    id='dugme'
                                    type="file"
                                    onChange={handleImageChange}
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={4}>
                            {
                                levelData.plan_photo
                                    ?
                                    <DoneAllIcon fontSize='large' color='success' />
                                    :
                                    <CloseIcon fontSize='large' color='error' />
                            }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                polygonLines.length > 4 && isPathClosed
                                    ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography variant='subtitle1' color='green' >Obiljezen</Typography>
                                        <DoneAllIcon color='success' style={{ marginLeft: '7px' }} />
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography variant='subtitle1' color='red' >Nije obiljezen</Typography>
                                        <CloseIcon color='error' style={{ marginLeft: '7px' }} />
                                    </div>
                            }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                loading
                                    ?
                                    <ShowLoading />
                                    :
                                    <Button
                                        disabled={!(polygonLines.length > 4 && isPathClosed)}
                                        fullWidth
                                        variant='contained'
                                        color='success'
                                        type='submit'
                                    >
                                        Dodaj
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>

            {
                buildingPhoto
                    ?
                    <div
                        style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + buildingPhoto})` }}
                        className={classes.addLevelPhoto}>
                        <canvas
                            height={643}
                            width={1133}
                            ref={canvasRef}
                            onClick={handleCanvasClick}
                            className={classes.canvas}
                        >
                        </canvas>
                        <div className={classes.clearCanvasButton}>
                            <Button
                                disableElevation
                                fullWidth
                                variant='contained'
                                color='secondary'
                                onClick={handleClearCanvas}
                            >
                                Resetuj
                            </Button>
                        </div>
                    </div>
                    :
                    <div className={classes.noPlanPhotoSelected}>
                        <Typography variant='h3' style={{ color: 'black' }}>
                            Izaberi zgradu
                        </Typography>
                    </div>
            }

        </div>
    );
};

export default AddLevelSection;