import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkJwtTokenAction, emptyJwtTokenAction } from './actions/admins_action';
import { useDispatch, useSelector } from 'react-redux';

const CheckLoginRoute = ({ children, type }) => {

    const dispatch = useDispatch();

    const jwtTokenCheckSelectorData = useSelector(state => state.checkJwtTokenState);
    const { jwtToken } = jwtTokenCheckSelectorData;

    React.useEffect(() => {
        //dispatch(emptyJwtTokenAction());
        dispatch(checkJwtTokenAction());
    }, []);

    return (

        typeof jwtToken !== 'undefined'
            ?
            (
                !jwtToken.success
                    ?
                    children
                    :
                    <Navigate to='/' />
            )
            :
            null
    );
};

export default CheckLoginRoute;
