import React from 'react';
import useStyles from './style';
import { DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const GalleryDialog = ({ open, setOpen, gallery }) => {

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <Carousel
                showThumbs={false}
                emulateTouch
                className={classes.galleryCarousel}
                swipeable
                showStatus={false}>
                {
                    gallery.map((photo, index) => {
                        return (
                            <img
                                src={process.env.REACT_APP_UPLOADS_PATH + photo}
                                // style={{ width : '100%' }}
                                key={index}
                                alt='Apartment'
                                className={classes.galleryPhoto}
                            />
                        )
                    })
                }
            </Carousel>
            <DialogActions>
                <Button onClick={handleClose} color='error'>
                    Zatvori
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default GalleryDialog