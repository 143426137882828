
export const addNewBuildingReducer = (newBuildingData = {}, action) => {
    switch( action.type ) {
        case 'REQUEST_ADD_NEW_BUILDING':
            return {
                loading : true
            }
        case 'SUCCESS_ADD_NEW_BUILDING':
        case 'ERROR_ADD_NEW_BUILDING':
            return {
                loading : false,
                newBuildingData : action.payload
            }
        default : return newBuildingData;
    }
};

export const getBuildingsReducer = (getBuildingsData = {}, action) => {
    switch( action.type ) {
        case 'REQUEST_GET_BUILDINGS':
            return {
                loading : true
            }
        case 'SUCCESS_GET_BUILDINGS':
        case 'ERROR_GET_BUILDINGS':
            return {
                loading : false,
                getBuildingsData : action.payload
            }
        default : return getBuildingsData;
    }
};

export const getBuildingPhotoReducer = ( buildingPhotoData = {}, action ) => {
    switch( action.type ){
        case 'REQUEST_GET_BUILDING_PHOTO' :
            return {
                loading : true
            }
        case 'SUCCESS_GET_BUILDING_PHOTO':
        case 'ERROR_GET_BUILDING_PHOTO':
            return {
                loading : false,
                buildingPhotoData : action.payload
            }
        default : return buildingPhotoData;
    }
};