import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkJwtTokenAction, emptyJwtTokenAction, checkSuperAdminAction } from './actions/admins_action';
import { useDispatch, useSelector } from 'react-redux';

const ProtectedRoute = ({ children, type }) => {

    const dispatch = useDispatch();

    const jwtTokenCheckSelectorData = useSelector(state => state.checkSuperAdminState);
    const { superAdminCheck } = jwtTokenCheckSelectorData;

    React.useEffect(() => {
        // dispatch(emptyJwtTokenAction());
        // dispatch(checkJwtTokenAction());
        dispatch(checkSuperAdminAction());
    }, []);

    return (

        typeof superAdminCheck !== 'undefined'
            ?
            (
                superAdminCheck.success
                    ?
                    children
                    :
                    <Navigate to='/login' />
            )
            :
            null
    );
};

export default ProtectedRoute;
