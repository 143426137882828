import React, { useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import useStyle from './style';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@material-ui/core';
import noPhoto from '../assets/photos/noPhoto.jpeg';

const EditOtherApartmentPhotosDialog = ({ open, setOpen, apartmentData, setApartmentData, readFileAsBase64 }) => {

    const classes = useStyle();

    const myInput = useRef();

    const [photoType, setPhotoType] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImagesChange = async (e) => {
        switch (photoType) {
            case 0:
                setApartmentData({
                    ...apartmentData,
                    plan_photo_file: await readFileAsBase64(e.target.files[0]),
                    plan_photo_name: e.target.files[0].name
                });
                break;
            case 1:
                setApartmentData({
                    ...apartmentData,
                    orbital_view_file: await readFileAsBase64(e.target.files[0]),
                    orbital_view_name: e.target.files[0].name
                });
                break;
            case 2:
                setApartmentData({
                    ...apartmentData,
                    three_sixty_view_file: await readFileAsBase64(e.target.files[0]),
                    three_sixty_view_name: e.target.files[0].name
                });
                break;
            default: return;
        }
    };

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                Edit galerije apartmana
            </DialogTitle>

            <DialogContent>

                <div className={classes.otherApartmentPhotosDiv}>
                    <div style={{ width: '350px', position: 'relative' }}>
                        <img 
                            style={{ width: '100%' }} 
                            src={apartmentData?.plan_photo ?
                                apartmentData.plan_photo_name ?
                                apartmentData.plan_photo_file :
                                process.env.REACT_APP_UPLOADS_PATH + apartmentData.plan_photo :
                                noPhoto} />
                        <IconButton
                            onClick={() => { myInput.current.click(); setPhotoType(0); }}
                            size='medium'
                            color='success'
                            style={{ border: '1px solid black', backgroundColor: 'white', position: 'absolute', top: '5px', right: '5px' }} >
                            <EditIcon />
                        </IconButton>
                        <div style={{ border: '1px solid black', position: 'absolute', left: '5px', top: '5px', backgroundColor: 'white', color: 'black', padding: '5px' }}>
                            <Typography variant='h6'>
                                Tlocrt
                            </Typography>
                        </div>
                    </div>
                    <div style={{ width: '350px', position: 'relative' }}>
                        <img 
                            style={{ width: '100%' }} 
                            src={apartmentData?.orbital_view ?
                                apartmentData.orbital_view_name ?
                                apartmentData.orbital_view_file :
                                process.env.REACT_APP_UPLOADS_PATH + apartmentData.orbital_view :
                                noPhoto} />
                        <IconButton
                            onClick={() => { myInput.current.click(); setPhotoType(1); }}
                            size='medium'
                            color='success'
                            style={{ border: '1px solid black', backgroundColor: 'white', position: 'absolute', top: '5px', right: '5px' }} >
                            <EditIcon />
                        </IconButton>
                        <div style={{ border: '1px solid black', position: 'absolute', left: '5px', top: '5px', backgroundColor: 'white', color: 'black', padding: '5px' }}>
                            <Typography variant='h6'>
                                360
                            </Typography>
                        </div>
                    </div>
                    <div style={{ width: '350px', position: 'relative' }}>
                        <img 
                            style={{ width: '100%' }} 
                            src={apartmentData?.three_sixty_view ?
                                apartmentData.three_sixty_view_name ?
                                apartmentData.three_sixty_view_file :
                                process.env.REACT_APP_UPLOADS_PATH+ apartmentData.three_sixty_view :
                                noPhoto} />
                        <IconButton
                            onClick={() => { myInput.current.click(); setPhotoType(2); }}
                            size='medium'
                            color='success'
                            style={{ border: '1px solid black', backgroundColor: 'white', position: 'absolute', top: '5px', right: '5px' }} >
                            <EditIcon />
                        </IconButton>
                        <div style={{ border: '1px solid black', position: 'absolute', left: '5px', top: '5px', backgroundColor: 'white', color: 'black', padding: '5px' }}>
                            <Typography variant='h6'>
                                Orbital
                            </Typography>
                        </div>
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Izadji</Button>
                <input
                    ref={myInput}
                    onChange={handleImagesChange}
                    id='dugme'
                    type="file"
                    accept='image/*'
                    hidden
                />
            </DialogActions>
        </Dialog>
    )
}

export default EditOtherApartmentPhotosDialog;