import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    appBar: {
        //border : '2px solid red',
        padding: '20px'
    },
    toolBar: {
        //border : '2px solid black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    logout: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px'
        }
    },
    editPrice: {
        marginLeft : '10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft : '5px'
        },
    },
    tableDiv: {
        // border: '2px solid red',
        width: '75%',
        margin: 'auto',
        //marginTop: '3%',
        padding: '10px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '6%',
            width: '100%',
            padding: '0px'
        },
    },
    searchFieldDiv: {
        width: '75%',
        margin: 'auto',
        marginTop: '3%',
        padding: '10px',
    },
    table: {
        //border: '2px solid green',
        borderCollapse: 'collapse',
        width: '100%',
        "& th": {
            fontSize: '21px',
            textAlign: 'left',
            backgroundColor: '#26A65B',
            color: 'white',
            padding: '10px',
            //border : '1px solid #006442'
        },
        "& td": {
            fontSize: '18px',
            textAlign: 'left',
            padding: '10px',
            //border : '1px solid #006442',
            fontWeight: 'bold'
        },
        "& tr": {
            height: '60px',
        },
        "& tr:nth-child(odd)": {
            backgroundColor: '#f2f2f2'
        },
    },
    eyeTd: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchField: {
        // "& .MuiOutlinedInput-root": {
        //     border : '2px solid black'
        // }
        // '& .MuiInput-underline:after': {
        //     border: '1px solid green',
        //   },
    },
    test: {
        '&.MuiTableCell-head': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
}));

export default useStyles;