import React, { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import MainView from './Main View/MainView';
import Login from './Login/Login';
import RegularAdminPanel from './Regular Admin/RegularAdminPanel';
import SuperAdminPanel from './Super Admin/SuperAdminPanel';
import ProtectedRouteSuperAdmin from './ProtectedRouteSuperAdmin';
import ProtectedRouteRegularAdmin from './ProtectedRouteRegularAdmin';
import WithNavBar from './WithNavBar';
import WithoutNavBar from './WithoutNavBar';
import CheckLoginRoute from './CheckLoginRoute';

const THEME = createTheme({
    typography : {
        allVariants : {
                fontFamily : "'Oswald', sans-serif",
        }
    }
});

export const GlobalContext = createContext();

const App = () => {

    const [currentDisplay, setCurrentDisplay] = React.useState(JSON.parse(localStorage.getItem('currentDisplay')) || 0);

    const [ls_parameters, set_ls_parameters] = React.useState(JSON.parse(localStorage.getItem('parameters')) || [[0,100],[0,100],[0,100],-1]);

    const [currentLanguage, setCurrentLanguage] = React.useState(JSON.parse(localStorage.getItem('currentLanguage')) || 0);

    return (

        <ThemeProvider theme={THEME}>

            <BrowserRouter>

                <GlobalContext.Provider value={{
                    currentDisplay,
                    setCurrentDisplay,
                    ls_parameters,
                    set_ls_parameters,
                    currentLanguage,
                    setCurrentLanguage
                }}>


                    <Routes>

                        <Route element={<WithNavBar />}>

                            <Route path='/' element={<MainView />} />

                            <Route
                                path='/login'
                                element={
                                    <CheckLoginRoute>
                                        <Login />
                                    </CheckLoginRoute>
                                }
                            />

                        </Route>

                        <Route element={<WithoutNavBar />}>

                            <Route
                                path='/super-admin'
                                element={
                                    <ProtectedRouteSuperAdmin>
                                        <SuperAdminPanel />
                                    </ProtectedRouteSuperAdmin>
                                }
                            />
                            <Route
                                path='/regular-admin'
                                element={
                                    <ProtectedRouteRegularAdmin>
                                        <RegularAdminPanel />
                                    </ProtectedRouteRegularAdmin>
                                }
                            />

                        </Route>


                    </Routes>

                </GlobalContext.Provider>

            </BrowserRouter>

        </ThemeProvider>
    );
};

export default App;