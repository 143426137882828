import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Grid, Button, Select, Menu, MenuItem, InputLabel, FormControl, TextField, LinearProgress } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import { getLevelPlanPhotoAction } from '../actions/levels_action';
import { addNewApartmentAction } from '../actions/apartments_action';


const initialApartmentData = {
    number: '',
    building: '',
    level: '',
    type: '',
    orientation: [],
    area: '',
    price: '',
    status: '',
    gallery: [],
    plan_photo: '',
    orbital_view: '',
    three_sixty_view: '',
    pdf_catalogue: ''
};

const types = [
    {
        name: 'Garsonjera',
        value: 1
    },
    {
        name: 'Jednosoban',
        value: 2
    },
    {
        name: 'Dvosoban',
        value: 3
    },
    {
        name: 'Trosoban',
        value: 4
    },
    {
        name: 'Cetvorosoban',
        value: 5
    },
    {
        name: 'Petosoban',
        value: 6
    },
];

const orientations = ['Istok', 'Jug', 'Zapad', 'Sjever'];

const AddApartmentSection = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const canvasRef = React.useRef();

    const [polygonLines, setPolygonLines] = React.useState([]);

    const [firstCircle, setFirstCircle] = React.useState(null);

    const [isPathClosed, setIsPathClosed] = React.useState(false);

    const [apartmentData, setApartmentData] = React.useState(initialApartmentData);

    const [buildingNames, setBuildingNames] = React.useState([]);

    const [planPhoto, setPlanPhoto] = React.useState(null);

    const levelPlanPhotoSelector = useSelector(state => state.levelPlanPhotoState);
    const { levelPhotoPlan } = levelPlanPhotoSelector;

    const newApartmentSelector = useSelector(state => state.addNewApartmentState);
    const { loading, newApartmentData } = newApartmentSelector;

    React.useEffect(() => {
        const getBuildingNames = async () => {
            try {
                const api_url = process.env.REACT_APP_API_URL;
                const { data } = await axios({
                    method: 'GET',
                    url: `${api_url}/buildings/building-names-with-levels`,
                    responseType: 'json'
                });
                setBuildingNames([...data.buildingWithLevels]);
            } catch (error) {
                console.log(error);
            }
        };
        getBuildingNames();
    }, []);

    React.useEffect(() => {
        if (apartmentData.building && apartmentData.level) {
            dispatch(getLevelPlanPhotoAction(apartmentData.level));
        }
    }, [apartmentData.level]);

    React.useEffect(() => {
        if (typeof levelPhotoPlan !== 'undefined') {
            setPlanPhoto(levelPhotoPlan.photoPlan)
        }
    }, [levelPhotoPlan]);

    React.useEffect(() => {
        if (polygonLines.length > 0 && !isPathClosed) {
            drawPolygon();
        }
    }, [polygonLines]);


    const handleAddApartment = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('number', apartmentData.number);
        formData.append('building', apartmentData.building);
        formData.append('level', apartmentData.level);
        formData.append('type', apartmentData.type);
        formData.append('orientation', JSON.stringify(apartmentData.orientation));
        formData.append('area', apartmentData.area);
        formData.append('price', apartmentData.price);
        formData.append('status', apartmentData.status);
        formData.append('orbital_view_photo', apartmentData.orbital_view);
        formData.append('three_sixty_view_photo', apartmentData.three_sixty_view);
        formData.append('plan_photo', apartmentData.plan_photo);
        formData.append('pdf_catalogue', apartmentData.pdf_catalogue);
        formData.append('polygon_stringified', JSON.stringify(polygonLines));
        apartmentData.gallery.forEach(photo => {
            formData.append('galleryPhotos', photo);
        });
        dispatch(addNewApartmentAction(formData));
    };

    const handleChange = (e) => {
        setApartmentData({
            ...apartmentData,
            [e.target.name]: e.target.value
        });
    };

    const getIndex = () => {
        return buildingNames.findIndex(building => building.id === apartmentData.building);
    };


    const handleImageChange = (e) => {
        if (e.target.files) {
            setApartmentData((prevData) => {
                return {
                    ...prevData,
                    gallery: prevData.gallery.concat([...e.target.files])
                }
            });
        }
    };

    const handleCanvasClick = (e) => {

        if (isPathClosed) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const rect = e.target.getBoundingClientRect();

        const context = canvasRef.current.getContext("2d");

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPolygonLines([
            ...polygonLines,
            { x, y }
        ]);

        context.beginPath();
        context.strokeStyle = '#ff0000';
        context.lineWidth = 2;
        context.arc(x, y, 8, 0, 2 * Math.PI);
        context.stroke();

        if (polygonLines.length === 0) {
            setFirstCircle({
                x,
                y,
                r: 12
            });
        }
    };

    const drawPolygon = () => {
        const context = canvasRef.current.getContext("2d");
        context.lineWidth = 2;
        context.fillStyle = 'rgba(129,215,66,0.3)';
        context.strokeStyle = '#ff0000';
        context.beginPath();
        context.moveTo(polygonLines[0].x, polygonLines[0].y);
        for (let index = 1; index < polygonLines.length; index++) {
            context.lineTo(polygonLines[index].x, polygonLines[index].y);
        }
        if (polygonLines.length > 1) {
            const d = Math.sqrt(Math.pow((firstCircle.x - polygonLines[polygonLines.length - 1].x), 2) + Math.pow((firstCircle.y - polygonLines[polygonLines.length - 1].y), 2));
            if (d <= firstCircle.r) {
                setIsPathClosed(true);
                const pl = [...polygonLines];
                pl[pl.length - 1].x = pl[0].x;
                pl[pl.length - 1].y = pl[0].y;
                setPolygonLines([...pl]);
                context.closePath();
                context.fill();
            }
        }
        context.stroke();
    };

    const handleClearCanvas = () => {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setPolygonLines([]);
        setIsPathClosed(false);
    };

    const ShowLoading = () => {
        return (
            <div>
                <LinearProgress color='success' />
            </div>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success">
                <AlertTitle>Uspješno</AlertTitle>
                Novi stan dodat!
            </Alert>
        );
    };

    const ShowError = () => {
        return (
            <Alert severity="error">
                <AlertTitle>Greška</AlertTitle>
                Neuspješno dodavanje stana.
            </Alert>
        );
    };

    return (
        <div className={classes.addApartmentSection}>
            <div className={classes.addApartmentForm}>
                <Typography variant='h5' style={{ color: 'black', marginBottom: '20px', textAlign: 'center' }} >Novi stan</Typography>
                <div className={classes.addBuildingMessage}>
                    {
                        typeof newApartmentData !== 'undefined'
                            ?
                            newApartmentData.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                    }
                </div>
                <form onSubmit={handleAddApartment} className={classes.apartmentForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Zgrada</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={apartmentData.building}
                                    label="Zgrada"
                                    name='building'
                                    onChange={handleChange}
                                >
                                    {
                                        buildingNames !== null
                                            ?
                                            buildingNames.map((building, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={building.id}>
                                                        {building.name}
                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sprat</InputLabel>
                                <Select
                                    disabled={!apartmentData.building}
                                    labelId="demo-simple-select-label"
                                    value={apartmentData.level}
                                    label="Redni broj sprata"
                                    name='level'
                                    onChange={handleChange}
                                >
                                    {
                                        apartmentData.building
                                            ?
                                            buildingNames[getIndex()].levels.map((level, index) => {
                                                return (
                                                    <MenuItem key={index} value={level.id} > {level.level_number}. </MenuItem>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Broj stana"
                                name="number"
                                type="text"
                                value={apartmentData.number}
                                onChange={handleChange}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tip</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={apartmentData.type}
                                    label="Tip"
                                    name='type'
                                    onChange={handleChange}
                                >
                                    {
                                        types.map((type, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={type.value}>
                                                    {type.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Orijentacija</InputLabel>
                                <Select
                                    multiple
                                    labelId="demo-simple-select-label"
                                    value={apartmentData.orientation}
                                    label="Orijentacija"
                                    name='orientation'
                                    onChange={handleChange}
                                >
                                    {
                                        orientations.map((type, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={type}>
                                                    {type}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Povrsina"
                                name="area"
                                type="string"
                                value={apartmentData.area}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Cijena"
                                name="price"
                                type="string"
                                value={apartmentData.price}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    value={apartmentData.status}
                                    label="Status"
                                    name='status'
                                    onChange={handleChange}
                                >
                                    <MenuItem value={false} >Slobodan</MenuItem>
                                    <MenuItem value={true} >Prodat</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija</Typography>
                            <Button
                                fullWidth
                                disableElevation
                                variant="contained"
                                component="label"
                                color={apartmentData.gallery.length > 0 ? 'success' : 'inherit'}
                            >
                                {
                                    apartmentData.gallery.length > 0
                                        ?
                                        <DoneAllIcon fontSize='small' />
                                        :
                                        <AddIcon fontSize='small' />
                                }
                                <input
                                    id='dugme'
                                    type="file"
                                    onChange={handleImageChange}
                                    multiple
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Typography style={{ marginRight: '10px' }} variant='h5'>Tlocrt</Typography>
                            <Button
                                fullWidth
                                disableElevation
                                variant="contained"
                                component="label"
                                color={apartmentData.plan_photo ? 'success' : 'inherit'}
                            >
                                {
                                    apartmentData.plan_photo
                                        ?
                                        <DoneAllIcon fontSize='small' />
                                        :
                                        <AddIcon fontSize='small' />
                                }
                                <input
                                    id='dugme1'
                                    type="file"
                                    onChange={(e) => {
                                        //console.log(e.target.files[0]);
                                        setApartmentData({
                                            ...apartmentData,
                                            plan_photo: e.target.files[0]
                                        })
                                    }}
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Typography style={{ marginRight: '10px' }} variant='h5'>Orbital</Typography>
                            <Button
                                fullWidth
                                disableElevation
                                variant="contained"
                                component="label"
                                color={apartmentData.orbital_view ? 'success' : 'inherit'}

                            >
                                {
                                    apartmentData.orbital_view
                                        ?
                                        <DoneAllIcon fontSize='small' />
                                        :
                                        <AddIcon fontSize='small' />
                                }
                                <input
                                    //id='dugme2'
                                    type="file"
                                    onChange={(e) => {
                                        //console.log(e.target.files[0]);
                                        setApartmentData({
                                            ...apartmentData,
                                            orbital_view: e.target.files[0]
                                        })
                                    }}
                                    accept=".jpg,.jpeg,.png"
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Typography style={{ marginRight: '10px' }} variant='h5'>360&deg;</Typography>
                            <Button
                                fullWidth
                                disableElevation
                                variant="contained"
                                component="label"
                                color={apartmentData.three_sixty_view ? 'success' : 'inherit'}

                            >
                                {
                                    apartmentData.three_sixty_view
                                        ?
                                        <DoneAllIcon fontSize='small' />
                                        :
                                        <AddIcon fontSize='small' />
                                }
                                <input
                                    id='dugme3'
                                    type="file"
                                    onChange={(e) => {
                                        //console.log(e.target.files[0]);
                                        setApartmentData({
                                            ...apartmentData,
                                            three_sixty_view: e.target.files[0]
                                        })
                                    }}
                                    accept=".jpg,.jpeg,.png"
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={12}>
                            <Typography style={{ marginRight: '10px' }} variant='h5'>Pdf katalog</Typography>
                            <Button
                                fullWidth
                                disableElevation
                                variant="contained"
                                component="label"
                                color={apartmentData.pdf_catalogue ? 'success' : 'inherit'}
                            >
                                {
                                    apartmentData.pdf_catalogue
                                        ?
                                        <DoneAllIcon fontSize='small' />
                                        :
                                        <AddIcon fontSize='small' />
                                }
                                <input
                                    id='dugme4'
                                    type="file"
                                    onChange={(e) => {
                                        //console.log(e.target.files[0]);
                                        setApartmentData({
                                            ...apartmentData,
                                            pdf_catalogue: e.target.files[0]
                                        })
                                    }}
                                    accept='.pdf'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={12}>
                            {
                                loading
                                    ?
                                    <ShowLoading />
                                    :
                                    <Button fullWidth variant='contained' type='submit' color='success' >
                                        Dodaj
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>
            {
                planPhoto
                    ?
                    <div
                        style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + planPhoto})` }}
                        className={classes.addApartmentPhoto}>
                        <canvas
                            height={643}
                            width={1133}
                            ref={canvasRef}
                            onClick={handleCanvasClick}
                            className={classes.canvas}>
                        </canvas>
                        <div className={classes.clearCanvasButton}>
                            <Button
                                disableElevation
                                fullWidth
                                variant='contained'
                                color='secondary'
                                onClick={handleClearCanvas}
                            >
                                Resetuj
                            </Button>
                        </div>
                    </div>
                    :
                    <div className={classes.noPlanPhotoSelected}>
                        <Typography variant='h3' style={{ color: 'black' }}>
                            Izaberi zgradu i sprat
                        </Typography>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            marginTop: '20px',
                            width: '20%'
                        }}>
                            {
                                apartmentData.building
                                    ?
                                    <div className={classes.test}>
                                        <DoneAllIcon fontSize='medium' color='success' />
                                        <Typography variant='h6' style={{ color: 'black' }} >Zgrada</Typography>
                                    </div>
                                    :
                                    <div className={classes.test}>
                                        <CloseIcon fontSize='medium' color='error' />
                                        <Typography variant='h6' style={{ color: 'black' }} >Zgrada</Typography>
                                    </div>
                            }
                            {
                                apartmentData.level
                                    ?
                                    <div className={classes.test}>
                                        <DoneAllIcon fontSize='medium' color='success' />
                                        <Typography variant='h6' style={{ color: 'black' }} >Sprat</Typography>
                                    </div>
                                    :
                                    <div className={classes.test}>
                                        <CloseIcon fontSize='medium' color='error' />
                                        <Typography variant='h6' style={{ color: 'black' }} >Sprat</Typography>
                                    </div>
                            }
                        </div>
                    </div>
            }
        </div>
    );
};

export default AddApartmentSection