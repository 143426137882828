import React from 'react';
import { Typography, CssBaseline, AppBar, Toolbar, IconButton, Button, Menu, MenuItem } from '@mui/material';
import useStyles from './style';
import logo from '../assets/photos/logo.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHeaderDataAction } from '../actions/admins_action';
import { languagesMenuObject } from './languages';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18next from '../i18next';
import { t } from 'i18next';

const NavigationBar = () => {

    const { currentDisplay, setCurrentDisplay, currentLanguage, setCurrentLanguage } = useContext(GlobalContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const [anchorLanguage, setAnchorLanguage] = React.useState(null);

    const [header, setHeader] = React.useState({
        phoneNumber: '',
        emailAddress: ''
    });

    const [showModal, setShowModal] = React.useState(false);

    const headerDataSelector = useSelector(state => state.headerDataState);
    const { headerData } = headerDataSelector;
    
    const handleOpenLanguageMenu = (event) => {
        setAnchorLanguage(event.currentTarget);
    };

    const handleCloseLanguageMenu = () => {
        setAnchorLanguage(null);
    };

    React.useEffect(() => {
        dispatch(getHeaderDataAction());
    }, []);

    React.useEffect(() => {
        if (typeof headerData !== 'undefined') {
            if (headerData.success) {
                delete headerData.success;
                document.querySelector('meta[name="description"]').setAttribute("content", headerData.pageDescription);
                document.title = headerData.pageTitle;
                setHeader({ ...headerData });
            }
        };
    }, [headerData]);

    const handleSwitchLanguage = (lang, index) => {
        localStorage.setItem('currentLanguage', JSON.stringify(index));
        setCurrentLanguage(index);
        i18next.changeLanguage(`${lang.toLowerCase()}`)
    };

    return (
        <>
            <CssBaseline />
            <AppBar
                elevation={0}
                position="static"
                className={classes.navBar}
                style={{ backgroundColor: 'white' }}>
                <Toolbar className={classes.navToolBar}>
                    <div className={classes.logoAndOptions}>
                        <img
                            src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`}
                            alt="Logo"
                            className={classes.logo}
                            width={319}
                            height={94}
                            onClick={() => {
                                navigate('/');
                                localStorage.setItem('currentDisplay', JSON.stringify(0));
                                setCurrentDisplay(0);
                            }} />
                        <div className={classes.options}>
                            <Typography
                                onClick={() => {
                                    navigate('/');
                                    localStorage.setItem('currentDisplay', JSON.stringify(0));
                                    setCurrentDisplay(0);
                                }}
                                variant='h6'
                                className={classes.singleOption}>
                                {t("Kompleks")}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    navigate('/');
                                    localStorage.setItem('currentDisplay', JSON.stringify(4));
                                    setCurrentDisplay(4);
                                }}
                                variant='h6'
                                className={classes.singleOption}>
                                {t("Tabela kompleksa")}
                            </Typography>

                            <div className={classes.navBarLanguagePicker}>
                                <Button disableElevation disableRipple onClick={handleOpenLanguageMenu} style={{ color : 'black' }}>
                                    <img src={languagesMenuObject[currentLanguage].flag} width={37} alt='Flag' />
                                    <Typography
                                        variant='subtitle1'
                                        style={{
                                            marginLeft: '5px',
                                            // marginRight: '5px'
                                        }}>
                                        {languagesMenuObject[currentLanguage].code}
                                    </Typography>
                                    {/* <ExpandMoreIcon /> */}
                                </Button>
                                <Menu
                                    anchorEl={anchorLanguage}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={Boolean(anchorLanguage)}
                                    onClose={handleCloseLanguageMenu}
                                >
                                    {languagesMenuObject.map((language, index) => (
                                        <MenuItem
                                            style={{
                                                backgroundColor: index === currentLanguage ? '#0F79A1' : 'white',
                                                color: index === currentLanguage ? 'white' : 'black'
                                            }}
                                            key={index}
                                            onClick={() => { handleSwitchLanguage(language.code, index); }}
                                            className={classes.menuItem}>
                                            <img src={language.flag} width={22} style={{ marginRight: '5px' }} alt='Flag' />
                                            <div>
                                                <Typography variant='subtitle2' >{language.code}</Typography>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>

                        </div>
                    </div>

                    <div className={classes.phoneDesktop}>
                        <Typography variant='h5'>
                            {header.phoneNumber}
                        </Typography>
                        <Typography variant='h5'>
                            {header.emailAddress}
                        </Typography>
                    </div>

                    <div className={classes.mobileLogoAndMenu}>
                        <div className={classes.logoMobile}>
                            <img
                                src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`}
                                alt="Logo"
                                width={200}
                                //height={94}
                                onClick={() => {
                                    navigate('/');
                                    localStorage.setItem('currentDisplay', JSON.stringify(0));
                                    setCurrentDisplay(0);
                                }}
                                style={{ marginTop: '10px', marginLeft: '10px', filter: 'brightness(0) invert(1)' }} />
                        </div>
                        <IconButton size='large' onClick={() => { setShowModal(true); }}>
                            <MenuIcon style={{ fontSize: '50px', color: 'white' }} />
                        </IconButton>
                    </div>
                    <div className={classes.mobilePhoneNumber}>
                        <Typography variant='h6'>
                        {t("Prodaja od investitora")} : {header.phoneNumber}
                        </Typography>
                    </div>

                </Toolbar>
            </AppBar>
            <div
                style={{
                    display: showModal ? 'flex' : 'none'
                }}
                onClick={() => { setShowModal(false) }}
                className={classes.modal}>
                <Typography
                    onClick={() => {
                        navigate('/');
                        localStorage.setItem('currentDisplay', JSON.stringify(0));
                        setCurrentDisplay(0);
                    }}
                    variant='h3'
                    style={{ color: 'white', marginBottom: '15px', marginLeft: '20px' }}>
                    {t("Kompleks")}
                </Typography>
                <Typography
                    onClick={() => {
                        navigate('/');
                        localStorage.setItem('currentDisplay', JSON.stringify(4));
                        setCurrentDisplay(4);
                    }}
                    variant='h3'
                    style={{ color: 'white', marginBottom: '15px', marginLeft: '20px' }}>
                    {t("Tabela kompleksa")}
                </Typography>
                 <div className={classes.languagesModal}>
                    {
                        languagesMenuObject.map( (languageItem, index) => {
                            return (
                                <div
                                    onClick={(e)=>{
                                        e.stopPropagation(); 
                                        handleSwitchLanguage(languageItem.code,index) 
                                    }} 
                                    className={classes.languageModalItem} 
                                    key={index}>
                                    <img 
                                        src={languageItem.flag} 
                                        alt='Flag' 
                                        width='40' 
                                        style={{ filter: currentLanguage === index ? 'brightness(100%)' : 'brightness(50%)' }} 
                                    />
                                    <Typography variant='subtitle2' style={{ color : 'white', marginLeft : '5px' }}>
                                        {languageItem.code}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                 </div>
            </div>
        </>
    )
}

export default NavigationBar;