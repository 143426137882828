import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, LinearProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBuildingAction } from '../actions/buildings_action';
import { deleteBuildingLevelAction } from '../actions/levels_action';
import { deleteApartmentAction } from '../actions/apartments_action';

const DeleteDialog = ({ open, setOpen, data }) => {

    const dispatch = useDispatch();

    const [password, setPassword] = React.useState('');

    const getBuildingsSelector = useSelector(state => state.getBuildingsState);
    const { getBuildingsData } = getBuildingsSelector;

    const buildingLevelsSelector = useSelector(state => state.buildingLevelsState);
    const { buildingLevelsData } = buildingLevelsSelector;

    const apartmentsForLevelSelector = useSelector(state => state.apartmentsForLevelState);
    const { apartmentsForLevel } = apartmentsForLevelSelector;

    const handleClose = () => {
        setOpen(false);
    };

    const getTitle = () => {
        switch (data.type) {
            case 0: return `Zelite da uklonite zgradu ${data.name} ?`;
            case 1: return `Zelite da uklonite ${data.level_number}. sprat?`
            case 2: return `Zelite da uklonite stan broj ${data.number}?`
            default: return;
        };
    };

    const getSuccessMessage = () => {
        switch (data.type) {
            case 0: return `Zgrada uspjesno uklonjena.`;
            case 1: return `Sprat uspjesno uklonjen.`;
            case 2: return `Stan uspjesno uklonjen.`;
            default: return;
        };
    };

    const getErrorMessage = () => {
        switch (data.type) {
            case 0: return `Greska u uklanjanju zgrade`;
            case 1: return `Greska u uklanjanju sprata`;
            case 2: return `Greska u uklanjanju stana`;
            default: return;
        };
    };

    const handleDeletion = (e) => {
        e.preventDefault();
        switch (data.type) {
            case 0:
                dispatch(deleteBuildingAction({
                    id: data.id,
                    password
                }));
                break;
            case 1:
                dispatch(deleteBuildingLevelAction({
                    id: data.id,
                    building: data.building,
                    password
                }));
            case 2:
                dispatch(deleteApartmentAction({
                    id: data.id,
                    password
                }));
            default: return;
        }
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success">
                <AlertTitle>Uspješno</AlertTitle>
                {getSuccessMessage()}
            </Alert>
        );
    };

    const ShowError = () => {
        return (
            <Alert severity="error">
                <AlertTitle>Greška</AlertTitle>
                {getErrorMessage()}
            </Alert>
        );
    };

    return (
        <Dialog
            maxWidth='xs'
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {getTitle()}
            </DialogTitle>

            {
                typeof getBuildingsData !== 'undefined'
                    ?
                    getBuildingsData.buildingDelete
                        ?
                        getBuildingsData.success
                            ?
                            <ShowSuccess />
                            :
                            <ShowError />
                        :
                        null
                    :
                    null
            }

            {
                typeof buildingLevelsData !== 'undefined'
                    ?
                    buildingLevelsData.levelDelete
                        ?
                        buildingLevelsData.success
                            ?
                            <ShowSuccess />
                            :
                            <ShowError />
                        :
                        null
                    :
                    null
            }

            {
                typeof apartmentsForLevel !== 'undefined'
                    ?
                    apartmentsForLevel.apartmentDelete
                        ?
                        apartmentsForLevel.success
                            ?
                            <ShowSuccess />
                            :
                            <ShowError />
                        :
                        null
                    :
                    null
            }

            <DialogContent>
                <form id='form' onSubmit={handleDeletion} style={{ padding: '10px' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Unesite sifru"
                                name="name"
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Odustani</Button>
                <Button
                    disableElevation
                    type='submit'
                    form='form'
                    variant='contained'
                    color='error'>
                    Ukloni
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog