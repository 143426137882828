export const loginAdminReducer = (adminLoginData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN_ADMIN':
            return {
                loading: true
            }
        case 'SUCCESS_LOGIN_ADMIN':
        case 'ERROR_LOGIN_ADMIN':
            // if (action.payload.email) {
            //     delete action.payload.success;
            //     localStorage.setItem('loggedAdminData', JSON.stringify(action.payload));
            // }
            return {
                loading: false,
                adminLoginData: action.payload
            }
        case 'EMPTY_LOGIN_ADMIN_DATA':
            adminLoginData = {};
            return adminLoginData;
        default:
            return adminLoginData;
    };
};

export const checkJwtTokenReducer = (jwtToken = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_CHECK_JWT':
        case 'ERROR_CHECK_JWT':
            return {
                jwtToken: action.payload
            }
        case 'EMPTY_CHECK_JWT':
            jwtToken = {};
            return jwtToken;
        default:
            return jwtToken;
    };
};

export const logoutUserReducer = (userLogout = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_LOGOUT_USER':
        case 'ERROR_LOGOUT_USER':
            localStorage.removeItem('loggedUserData');
            localStorage.removeItem('saCurrentPage');
            return {
                userLogout: action.payload
            }
        default:
            return userLogout;
    };
};

export const checkSuperAdminReducer = (superAdminCheck = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_CHECK_SUPER_ADMIN':
        case 'ERROR_CHECK_SUPER_ADMIN':
            return {
                superAdminCheck : action.payload
            }
        default:
            return superAdminCheck;
    };
};

export const checkRegularAdminReducer = (regularAdminCheck = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_CHECK_REGULAR_ADMIN':
        case 'ERROR_CHECK_REGULAR_ADMIN':
            return {
                regularAdminCheck : action.payload
            }
        default:
            return regularAdminCheck;
    };
};

export const getHeaderDataReducer = (headerData = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_HEADER_DATA':
        case 'ERROR_GET_HEADER_DATA':
            return {
                headerData : action.payload
            }
        default:
            return headerData;
    };
};

export const getComplexPhotoReducer = (complexPhotoData = {}, action) => {
    switch (action.type) {
        case 'SUCCESS_GET_COMPLEX_PHOTO':
        case 'ERROR_GET_COMPLEX_PHOTO':
            return {
                complexPhotoData : action.payload
            }
        default:
            return complexPhotoData;
    };
};