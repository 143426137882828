import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    sidePanelDiv: {
        padding: '10px',
        borderRight: '2px solid #D2D7D3',
        //border : '3px solid red',
        [theme.breakpoints.down('1500')]: {
            marginRight: '40px',
            borderRight: '0px solid #D2D7D3',
        },
        [theme.breakpoints.down('960')]: {
            borderRight: '0px',
            borderBottom: '2px solid #D2D7D3',
            order: '2',
            padding: '10px',
            marginRight: '0px'
        }
    },
    title: {
        textAlign: 'center',
    },
    sliders: {
        //border : '2px solid black',
        width: '87%',
        //height : '80vh',
        margin: 'auto',
        marginTop: '40px',
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '3rem',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            gap: '1.8rem',
            marginTop: '35px',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    singleSlider: {
        //border : '2px solid red',
        padding: '0px',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '85%',
            // display : 'flex',
            // flexDirection : "column",
            // justifyContent : 'center',
            // alignItems : 'center',
        }
    },
    singleSliderRooms: {
        padding: '0px',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            //border : '3px solid yellow'
        }
    },
    roomsDiv: {
        //border : '2px solid red',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '10px',
        [theme.breakpoints.down('xs')]: {
            marginTop : '-15px'
            //gridTemplateColumns : '1fr 1fr'
        }
    },
    sliderElement: {
        //border : '2px solid red',
        [theme.breakpoints.down('xs')]: {
            marginTop : '-9px',
        },
        '& .MuiSlider-thumb': {
            color: '#0F79A1',
            padding: '12px',
        },
        '& 	.MuiSlider-valueLabel': {
            color: 'black',
            backgroundColor: 'transparent',
            fontSize: '21px',
            [theme.breakpoints.down('xs')]: {
                //border : '2px solid black',
                margin : '10px'
            }
        }
    }
}));

export default useStyles;