import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    appBar : {
        padding : '12px',
        borderBottom : '2px solid #D2D7D3',
    },
    toolBar : {
        //border : '2px solid black',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center'
    },
    logoAndOptions : {
        //border : '2px solid green',
        width : '60vw',
        padding : '5px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center'
    },
    options : {
        //border : '2px solid yellow',
        width : '60%',
        padding : '3px',
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
    },
    singleOption : {
        color : 'black',
        transition : '0.8s',
        cursor : 'pointer',
        '&:hover': {
            color : '#0074b9'
        },
    },
    singleOptionActive : {
        color : '#0074b9',
        borderBottom : '2px solid #0074b9'
    },
    /////////////////////////////////////////////////////
    addBuildingSection : {
        //border : '2px solid orange',
        height : '90vh',
        display : 'grid',
        gridTemplateColumns : '1fr 3fr',
    },
    addBuildingForm : {
        //border : '5px solid orangered',
        padding : '20px',
        
    },
    form : {
        width : "100%",
        margin : 'auto'
    },
    addBuildingPhoto : {
        //border : '2px solid gold',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '643px !important',
        width: '1133px',
        overflow: 'hidden',
        position : 'relative'
    },
    clearCanvasButton : {
        position : 'absolute',
        top : '0',
        left : '0',
        //border : '2px solid yellow',
        padding : '5px'
    },
    addBuildingMessage : {
        margin : '25px'
    },
    //////////////////////////////////////////////////////////////////////////////
    addLevelSection : {
       // border : '4px solid red',
        height : '90vh',
        display : 'grid',
        gridTemplateColumns : '1fr 3fr',
    },
    addLevelForm : {
        //border : '4px solid green',
        padding : '20px'
    },
    addLevelPhoto : {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        height: '643px !important',
        width: '1133px',
        overflow: 'hidden',
        position : 'relative',
    },
    addLevelPhotoMobile : {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100%',
        height: '1080px !important',
        width: '1080px',
        overflow: 'hidden',
        position : 'relative',
        //border : '5px solid black',
    },
    levelForm : {
        //border : '3px solid gray',
        width : '100%',
        margin : 'auto'
    },
    ////////////////////////////////////////
    addApartmentSection : {
        height : '90vh',
        display : 'grid',
        gridTemplateColumns : '1fr 3fr',
    },
    addApartmentForm : {
        padding : '20px'
    },
    addApartmentPhoto : {
        //border : "2px solid red",
        backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '643px !important',
        width: '1133px',
        overflow: 'hidden',
        position : 'relative',
        // backgroundSize: '75% 75%'
        backgroundSize: 'cover'
    },
    noPlanPhotoSelected : {
        display : "flex",
        flexDirection : 'column',
        justifyContent  :'center',
        alignItems : 'center'
    },
    test : {
        display : "flex",
        flexDirection : 'row',
        justifyContent  :'center',
        alignItems : 'center'
    },
    apartmentForm : {
        width : '95%',
        margin : 'auto'
    },
    buildingNameMarker: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4B77BE',
        color: 'white',
        width: '75px',
        height: '75px',
    },
    buildingAvailableMarker: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        color: 'black',
        width: '75px',
        height: '75px',
        zIndex: '20'

    },
    backgroundBlur: {
        border: '10px solid red',
        //filter : 'brightness(40%)',
        position: "absolute",
        left: "0",
        right: '0',
        top: '0',
        bottom: "0"
    },
    loading: {
        //filter : 'blur(-2px)',
        position: 'absolute',
        left: '50%',
        top: '26%',
        transform: "translate(-50%, 0)",
        zIndex: '50'
    },
    error: {
        position: 'absolute',
        left: '50%',
        top: '26%',
        transform: "translate(-50%, 0)",
        zIndex: '50'
    },
    polygon: {
        cursor: 'pointer',
        '&:hover': {
            fill: 'rgba(255,255,255,0.25) !important'
        },
        [theme.breakpoints.down('xs')]: {

        }
    },
    polygonApt: {
        '&:hover': {
            fill: 'rgba(30,93,188,0.31) !important'
        },
        [theme.breakpoints.down('xs')]: {

        }
    },
    editBuildingPolygon : {
        marginTop : '7px',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center'
    },
    levelMarker: {
        // width: '180px',
        //height: '35px',
        position: 'absolute',
        gridTemplateColumns: "1fr 1fr",
        // backgroundColor: '#4B77BE',
        backgroundColor : 'white',
        
    },
    photoPreview : {
        backgroundColor : 'white',
        position : 'absolute',
        zIndex : '50'
    },
    levelDisplayDiv: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '643px',
        width: '1133px',
        overflow: 'hidden',
        // backgroundSize: '75% 75%',
        backgroundSize: 'cover',
        borderLeft : '2px solid #D2D7D3'
    },
    buildingAndLevel: {
        position: 'absolute',
        top: '40px',
        right: '40px',
        display: 'grid',
        gridTemplateColumns: '1fr'
    },
    buildingAndLevelSubDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '120px',
        height: '120px',
        border: '1px solid #D2D7D3'
    },
    apartmentDetails: {
        position: 'absolute',
        left: '50px',
        bottom: '50px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    apartmentDetailsCard: {
        //padding : '10px',
        border: '1px solid #D2D7D3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '130px',
        height: '110px',
        '&:last-child': {
            width: '150px'
        }
    },
    editGalleryDiv : {
        //border : '2px solid black',
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gap : '10px',
        padding : '20px'
    },
    otherApartmentPhotosDiv : {
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gap : '10px',
        padding : '20px'
    },
    editLogoAndPhone : {
        //border : '2px solid black',
        width : '40%',
        margin : 'auto',
        marginTop : '4%',
        padding : '10px'
    },
    showLogo : {
        width : '300px', 
        position: 'relative', 
    },
    closeLogo : {
        position : 'absolute',
        top: '5px', 
        right: '5px', 
        backgroundColor: 'red' 
    }
}));

export default useStyles;