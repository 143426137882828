import React from 'react';
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField, Grid, Divider } from '@mui/material';
import { changeApartmentPriceAction } from '../actions/apartments_action';
import { AlertTitle, Alert } from '@mui/material';

const ChangePriceDialog = ({ open, setOpen, info, setInfo, dispatch, apartmentsData }) => {

    const [newPrice, setNewPrice] = React.useState(info.price);

    const handleClose = () => {
        setOpen(false);
        setInfo({});
    };

    const handleChangeApartmentPrice = () => {
        if (newPrice.length === 0 || +newPrice <= 0) {
            alert('Stavite ispravan format cijene');
        } else {
            dispatch(changeApartmentPriceAction({ apartmentId: info.id, newPrice }));
        }
    };

    const ShowError = () => {
        delete apartmentsData.priceUpdate;
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješna promjena cijene
                </Alert>
            </div>
        )
    };

    const ShowSuccess = () => {
        delete apartmentsData.priceUpdate;
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="success">
                    <AlertTitle>Ok</AlertTitle>
                    Cijena promijenjena
                </Alert>
            </div>
        )
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Nova cijena stana broj <strong>{info.number}</strong> u zgradi <strong>{info.building}</strong>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent >
                {
                    typeof apartmentsData !== 'undefined'
                        ?
                        apartmentsData.priceUpdate
                            ?
                            apartmentsData.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                        :
                        null
                }
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            required
                            label="Nova cijena"
                            name="newPrice"
                            type="number"
                            value={newPrice}
                            onChange={(e) => {
                                setNewPrice(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            onClick={handleChangeApartmentPrice}
                            disableElevation
                            fullWidth
                            variant='contained'
                            color='success'>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color='error'>
                    Izadji
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePriceDialog;