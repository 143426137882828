import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationMN from './locales/mn/translationMN.json';
import translationEN from './locales/en/translationEN.json';
import translationRU from './locales/ru/translationRU.json';
import translationUa from './locales/ua/translationUA.json';
import translationDe from './locales/de/translationDE.json';
import translationTr from './locales/tr/translationTR.json';

const resources = {
    me: {
        translation : translationMN
    },
    en: {
        translation : translationEN
    },
    ru: {
        translation : translationRU
    },
    ua: {
        translation : translationUa
    },
    de: {
        translation : translationDe
    },
    tr: {
        translation : translationTr
    }
};

const languages = ['me','en','ru','ua','de','tr'];

i18next.use(initReactI18next).init({
    resources,
    lng : languages[JSON.parse(localStorage.getItem('currentLanguage'))] || 'me' ,
    keySeparator : false,
    interpolation : {
        escapeValue : false
    }
});

export default i18next;