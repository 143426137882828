import mne from '../assets/flags/montenegroFlag.png';
import eng from '../assets/flags/greatBritainFlag.png';
import rus from '../assets/flags/russiaFlag.png';
import ukr from '../assets/flags/ukraineFlag.png';
import tur from '../assets/flags/turkeyFlag.png';
import ger from '../assets/flags/germanyFlag.png';
 
export const languagesMenuObject = [
    {
        code : 'ME',
        flag : mne
    },
    {
        code : 'EN',
        flag : eng
    },
    {
        code : 'RU',
        flag : rus
    },
    {
        code : 'UA',
        flag : ukr
    },
    {
        code : 'DE',
        flag : ger
    },
    {
        code : 'TR',
        flag : tur
    }
];
