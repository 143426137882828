
export const addNewLevelReducer = ( newLevelData = {}, action ) => {
    switch( action.type ){
        case 'REQUEST_ADD_NEW_LEVEL' :
            return {
                loading : true
            }
        case 'SUCCESS_ADD_NEW_LEVEL':
        case 'ERROR_ADD_NEW_LEVEL':
            return {
                loading : false,
                newLevelData : action.payload
            }
        default : return newLevelData;
    }
};

export const getBuildingLevelsReducer = ( buildingLevelsData = {}, action ) => {
    switch( action.type ){
        case 'REQUEST_GET_BUILDING_LEVELS' :
            return {
                loading : true
            }
        case 'SUCCESS_GET_BUILDING_LEVELS':
        case 'ERROR_GET_BUILDING_LEVELS':
            return {
                loading : false,
                buildingLevelsData : action.payload
            }
        default : return buildingLevelsData;
    }
};

export const getLevelPlanPhotoReducer = ( levelPhotoPlan = {}, action ) => {
    switch( action.type ){
        case 'REQUEST_GET_LEVEL_PLAN_PHOTO' :
            return {
                loading : true
            }
        case 'SUCCESS_GET_LEVEL_PLAN_PHOTO':
        case 'ERROR_GET_LEVEL_PLAN_PHOTO':
            return {
                loading : false,
                levelPhotoPlan : action.payload
            }
        default : return levelPhotoPlan;
    }
};