import React from 'react';
import useStyles from './style';
import { Typography, Button, Grid, TextField, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import kvart from '../assets/photos/kvart.jpg';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle } from '@mui/material';
import { addNewBuildingAction } from '../actions/buildings_action';
import AddIcon from '@mui/icons-material/Add';
import { getComplexPhotoAction } from '../actions/admins_action';


const initialBuildingData = {
    name: '',
    numOfLevels: '',
    buildingPhoto: ''
};

const AddBuildingSection = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [buildingData, setBuildingData] = React.useState(initialBuildingData);

    const [polygonLines, setPolygonLines] = React.useState([]);

    const [firstCircle, setFirstCircle] = React.useState(null);

    const [isPathClosed, setIsPathClosed] = React.useState(false);

    const canvasRef = React.useRef();

    const addNewBuildingSelector = useSelector(state => state.addNewBuildingState);
    const { loading, newBuildingData } = addNewBuildingSelector;

    const [complexPhoto, setComplexPhoto] = React.useState(null);

    const getComplexPhotoSelector = useSelector(state => state.getComplexPhotoState);
    const { complexPhotoData } = getComplexPhotoSelector;

    React.useEffect(() => {
        dispatch(getComplexPhotoAction());
    }, []);

    React.useEffect(() => {
        if (typeof complexPhotoData !== 'undefined') {
            if (complexPhotoData.success) {
                setComplexPhoto(complexPhotoData.complexPhoto);
            }
        }
    }, [complexPhotoData]);

    React.useEffect(() => {
        if (polygonLines.length > 0 && !isPathClosed) {
            drawPolygon();
        }
    }, [polygonLines]);


    const handleChange = (e) => {
        setBuildingData({
            ...buildingData,
            [e.target.name]: e.target.value
        })
    };

    const handleCanvasClick = (e) => {

        if (isPathClosed) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const rect = e.target.getBoundingClientRect();

        const context = canvasRef.current.getContext("2d");

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPolygonLines([
            ...polygonLines,
            { x, y }
        ]);

        context.beginPath();
        context.strokeStyle = '#ff0000';
        context.lineWidth = 2;
        context.arc(x, y, 8, 0, 2 * Math.PI);
        context.stroke();

        if (polygonLines.length === 0) {
            setFirstCircle({
                x,
                y,
                r: 12
            });
        }
    };

    const drawPolygon = () => {
        const context = canvasRef.current.getContext("2d");
        context.lineWidth = 2;
        context.fillStyle = 'rgba(129,215,66,0.3)';
        context.strokeStyle = '#ff0000';
        context.beginPath();
        context.moveTo(polygonLines[0].x, polygonLines[0].y);
        for (let index = 1; index < polygonLines.length; index++) {
            context.lineTo(polygonLines[index].x, polygonLines[index].y);
        }
        if (polygonLines.length > 1) {
            const d = Math.sqrt(Math.pow((firstCircle.x - polygonLines[polygonLines.length - 1].x), 2) + Math.pow((firstCircle.y - polygonLines[polygonLines.length - 1].y), 2));
            if (d <= firstCircle.r) {
                setIsPathClosed(true);
                const pl = [...polygonLines];
                pl[pl.length - 1].x = pl[0].x;
                pl[pl.length - 1].y = pl[0].y;
                setPolygonLines([...pl]);
                context.closePath();
                context.fill();
            }
        }
        context.stroke();
    };

    const handleClearCanvas = () => {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setPolygonLines([]);
        setIsPathClosed(false);
    };

    const handleAddBuilding = (e) => {
        e.preventDefault();
        if (polygonLines.length < 4 || !isPathClosed) {
            alert("Obiljezite zgradu na slici")
        } else {

            const formData = new FormData();

            formData.append('name', buildingData.name);
            formData.append('numOfLevels', buildingData.numOfLevels);
            formData.append('polygon', JSON.stringify(polygonLines));
            formData.append('buildingPhoto', buildingData.buildingPhoto);

            dispatch(addNewBuildingAction(formData));
        }
    };

    const ShowLoading = () => {
        return (
            <div>
                <LinearProgress color='success' />
            </div>
        )
    };

    const ShowSuccess = () => {
        return (
            <Alert severity="success">
                <AlertTitle>Uspješno</AlertTitle>
                Nova zgrada dodata!
            </Alert>
        );
    };

    const ShowError = () => {
        return (
            <Alert severity="error">
                <AlertTitle>Greška</AlertTitle>
                Neuspješno dodavanje zgrade.
            </Alert>
        );
    };

    return (
        <div className={classes.addBuildingSection}>
            <div className={classes.addBuildingForm}>
                <Typography variant='h5' style={{ color: 'black', marginBottom: '20px', textAlign: 'center' }} >Nova zgrada</Typography>
                <div className={classes.addBuildingMessage}>
                    {
                        typeof newBuildingData !== 'undefined'
                            ?
                            newBuildingData.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                    }
                </div>
                <form onSubmit={handleAddBuilding} className={classes.form}>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Ime"
                                name="name"
                                type="text"
                                value={buildingData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Broj spratova"
                                name="numOfLevels"
                                type="number"
                                value={buildingData.numOfLevels}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Button
                                disableElevation
                                fullWidth
                                variant="contained"
                                component="label"
                                color='inherit'
                            >
                                <AddIcon fontSize='small' />
                                Dodaj sliku zgrade
                                <input
                                    id='dugme'
                                    type="file"
                                    onChange={(e) => {
                                        setBuildingData({
                                            ...buildingData,
                                            buildingPhoto: e.target.files[0]
                                        })
                                    }}
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        <Grid item md={4}>
                            {
                                buildingData.buildingPhoto
                                    ?
                                    <DoneAllIcon fontSize='large' color='success' />
                                    :
                                    <CloseIcon fontSize='large' color='error' />
                            }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                polygonLines.length > 4 && isPathClosed
                                    ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography variant='subtitle1' color='green' >Obiljezena</Typography>
                                        <DoneAllIcon color='success' style={{ marginLeft: '7px' }} />
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography variant='subtitle1' color='red' >Nije obiljezena</Typography>
                                        <CloseIcon color='error' style={{ marginLeft: '7px' }} />
                                    </div>
                            }
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                loading
                                    ?
                                    <ShowLoading />
                                    :
                                    <Button
                                        disabled={!(polygonLines.length > 4 && isPathClosed)}
                                        fullWidth
                                        variant='contained'
                                        color='success'
                                        type='submit'
                                    >
                                        Dodaj
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div
                style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH+ complexPhoto})` }}
                className={classes.addBuildingPhoto}>
                <canvas
                    height={643}
                    width={1133}
                    ref={canvasRef}
                    onClick={handleCanvasClick}
                    className={classes.canvas}
                >
                </canvas>
                <div className={classes.clearCanvasButton}>
                    <Button
                        disableElevation
                        fullWidth
                        variant='contained'
                        color='secondary'
                        onClick={handleClearCanvas}
                    >
                        Resetuj
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default AddBuildingSection;