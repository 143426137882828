import { Typography, CircularProgress } from '@mui/material';
import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useStyle from './style';
//import noPhoto from '../assets/photos/noPhoto.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildingsAction } from '../actions/buildings_action';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import { getComplexPhotoAction } from '../actions/admins_action';
import { t } from 'i18next';

const ComplexDisplay = () => {

    const { currentDisplay, setCurrentDisplay } = useContext(GlobalContext);

    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('960'), { noSsr: true });

    const [showAvailable, setShowAvailable] = React.useState(null);

    const getBuildingsSelector = useSelector(state => state.getBuildingsState);
    const { loading, getBuildingsData } = getBuildingsSelector;

    const [complexPhoto, setComplexPhoto] = React.useState(null);

    const getComplexPhotoSelector = useSelector(state => state.getComplexPhotoState);
    const { complexPhotoData } = getComplexPhotoSelector;

    React.useEffect(() => {
        dispatch(getComplexPhotoAction());
    }, []);

    React.useEffect(() => {
        if (typeof complexPhotoData !== 'undefined') {
            if (complexPhotoData.success) {
                setComplexPhoto(complexPhotoData.complexPhoto);
                dispatch(getBuildingsAction());
            }
        }
    }, [complexPhotoData]);

    const getPolygonString = (polygon) => {
        let polygonString = '';
        for (let i = 0; i < polygon.length; i++) {
            const temp = polygon[i].x.toString() + ',' + polygon[i].y.toString() + ' ';
            polygonString += temp;
        };
        return polygonString;
    };

    const ShowLoading = () => {
        return (
            <div className={classes.loading}>
                <CircularProgress color='warning' thickness={4} size={300} />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div className={classes.error}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje zgrada
                </Alert>
            </div>
        )
    };

    const SvgPolygons = () => {
        return (
            <>
                <svg
                    viewBox={isMobile ? "0 0 1130 643" : "0 0 1133 643"}
                    className={classes.svg}
                >
                    {
                        getBuildingsData.buildings.map((building, index) => {
                            return (
                                <polygon
                                    onClick={(e) => {
                                        e.preventDefault()
                                        navigate(`?buildingId=${building.id}`);
                                        localStorage.setItem('currentDisplay', JSON.stringify(1));
                                        setCurrentDisplay(1);
                                    }}
                                    // onTouchStart={()=>{
                                    //     navigate(`?buildingId=${building.id}`);
                                    //     localStorage.setItem('currentDisplay', JSON.stringify(1));
                                    //     setCurrentDisplay(1);
                                    // }}
                                    onMouseEnter={!isMobile ? () => {
                                        setShowAvailable(index);
                                    } : null}
                                    onMouseLeave={!isMobile ? () => {
                                        setShowAvailable(null);
                                    } : null}
                                    className={classes.polygon}
                                    key={index}
                                    //preserveAspectRatio="none"
                                    points={getPolygonString(building.polygon)}
                                    style={{ fill: 'rgba(129,215,66,0.3)', strokeWidth: '1', cursor : 'pointer' }}
                                />
                            )
                        })
                    }
                    {
                        isMobile
                            ?
                            (
                                getBuildingsData.buildings.map((building, index) => {
                                    return (
                                        [<rect
                                            key={index}
                                            fill='#4B77BE'
                                            x={building.polygon[0].x - 30}
                                            y={building.polygon[0].y - 70}
                                            width="140"
                                            height="60" />,
                                        <text
                                            key={index}
                                            className={classes.textSvg}
                                            x={building.polygon[0].x + 40}
                                            y={building.polygon[0].y - 35}
                                            color='white'
                                            dominant-baseline="middle"
                                            text-anchor="middle">
                                            {building.name}
                                        </text>,
                                        <rect
                                            key={index}
                                            fill='white'
                                            x={building.polygon[0].x + 120 }
                                            y={building.polygon[0].y - 70}
                                            width="80"
                                            height="60" />,
                                        <text
                                            key={index}
                                            className={classes.textSvgTwo}
                                            x={building.polygon[0].x + 160}
                                            y={building.polygon[0].y - 35}
                                            color='white'
                                            dominant-baseline="middle"
                                            text-anchor="middle">
                                            {building.availableApartments}
                                        </text>,
                                        ]
                                    )
                                })
                            )
                            :
                            null
                    }

                </svg>
                {
                    !isMobile
                        ?
                        (
                            getBuildingsData.buildings.map((building, index) => {
                                return (
                                    [
                                        <div
                                            key={123}
                                            className={classes.buildingNameMarker}
                                            style={{
                                                left: `${building.polygon[0].x}px`,
                                                top: `${building.polygon[0].y - 70}px`,
                                            }}>
                                            <Typography variant='h5' style={{ marginBottom: '-3px' }}>{building.name}</Typography>
                                            <Typography variant='caption'>{t("Zgrada")}</Typography>
                                        </div>,
                                        <div
                                            key={1234}
                                            className={classes.buildingAvailableMarker}
                                            style={{
                                                left: `${building.polygon[0].x + 75}px`,
                                                top: `${building.polygon[0].y - 70}px`,
                                                display: (showAvailable === index ? 'flex' : 'none')
                                            }} >
                                            <Typography variant='h5' style={{ marginBottom: '-3px' }}>{building.availableApartments}</Typography>
                                            <Typography variant='caption'>{t("Dostupno")}</Typography>
                                        </div>
                                    ]
                                )
                            })
                        )
                        :
                        null
                }
            </>
        )
    }

    return (
        <div
            style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + complexPhoto})`, filter: `blur( ${loading ? '2px' : '0px'} )` }}
            className={classes.complexDisplayDiv}>
            {
                loading
                    ?
                    <ShowLoading />
                    :
                    null
            }
            {
                typeof getBuildingsData !== 'undefined'
                    ?
                    getBuildingsData.success
                        ?
                        <SvgPolygons />
                        :
                        <ShowError />
                    :
                    null
            }

        </div>
    );
};

export default ComplexDisplay;
