import React from 'react';
import useStyles from './style';
import { DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const GalleryDialog = ({ open, setOpen, photo }) => {

    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={open}
            onClose={handleClose}
            className={classes.orbitalDialog}
        >
            <div style={{ width: '100%' }}>
                <img src={process.env.REACT_APP_UPLOADS_PATH + photo} alt='Orbital View' style={{ width: '100%', height: '100%' }} />
            </div>
            <DialogActions>
                <Button onClick={handleClose} color='error'>
                    Zatvori
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default GalleryDialog