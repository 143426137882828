import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './style';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Alert, AlertTitle, Grid, Select, InputLabel, FormControl, Menu, MenuItem, TextField } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Button, IconButton } from '@mui/material';
import { getLevelPlanPhotoAction } from '../actions/levels_action';
import { getApartmentsForLevelAction, getApartmentToEditAction } from '../actions/apartments_action';
import { GlobalContext } from '../App';
import noPhoto from '../assets/photos/noPhoto.jpeg';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DeleteDialog from './DeleteDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditApartmentPolygonDialog from './EditApartmentPolygonDialog';
import EditApartmentGalleryDialog from './EditApartmentGalleryDialog';
import EditOtherApartmentPhotosDialog from './EditOtherApartmentPhotosDialog';
import { updateApartmentAction } from '../actions/apartments_action';

const initialApartmentData = {
  id: '',
  number: '',
  type: '',
  orientation: [],
  area: '',
  price: '',
  status: '',
  gallery: [],
  plan_photo: '',
  orbital_view: '',
  three_sixty_view: '',
  pdf_catalogue: '',
  polygon: []
};

const types = [
  {
    name: 'Garsonjera',
    value: 1
  },
  {
    name: 'Jednosoban',
    value: 2
  },
  {
    name: 'Dvosoban',
    value: 3
  },
  {
    name: 'Trosoban',
    value: 4
  },
  {
    name: 'Cetvorosoban',
    value: 5
  },
  {
    name: 'Petosoban',
    value: 6
  },
];

const orientations = ['Istok', 'Jug', 'Zapad', 'Sjever'];

const EditApartments = () => {

  const { setCurrentDisplay } = useContext(GlobalContext);

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEditGallery, setShowEditGallery] = React.useState(false);

  const [showEditOtherPhotos, setShowEditOtherPhotos] = React.useState(false);

  const [showEditApartmentPolygon, setShowEditApartmentPolygon] = React.useState(false);

  const [showDeletionDialog, setShowDeletionDialog] = React.useState(false);
  const [deletionDialogData, setDeletionDialogData] = React.useState({});

  const [apartmentData, setApartmentData] = React.useState(initialApartmentData);

  const [apartmentPicked, setApartmentPicked] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const [searchParams, setSearchParams] = useSearchParams();
  const levelId = searchParams.get('levelId');
  const buildingId = searchParams.get('buildingId');

  const [showApartmentDetails, setShowApartmentDetails] = React.useState(null);

  const [planPhoto, setPlanPhoto] = React.useState(null);

  const levelPlanPhotoSelector = useSelector(state => state.levelPlanPhotoState);
  const { levelPhotoPlan } = levelPlanPhotoSelector;

  const apartmentsForLevelSelector = useSelector(state => state.apartmentsForLevelState);
  const { loading, apartmentsForLevel } = apartmentsForLevelSelector;

  const apartmentToEditSelector = useSelector(state => state.getApartmentToEditState);
  const { apartmentToEditData } = apartmentToEditSelector;

  React.useEffect(() => {
    if (levelId) {
      dispatch(getLevelPlanPhotoAction(levelId));
    } else if (!levelId || !buildingId) {
      navigate('/');
      localStorage.setItem('currentDisplay', JSON.stringify(0));
      setCurrentDisplay(0);
    }
  }, [levelId]);

  React.useEffect(() => {
    if (typeof levelPhotoPlan !== 'undefined') {
      if (levelPhotoPlan.success) {
        setPlanPhoto(levelPhotoPlan.photoPlan);
        dispatch(getApartmentsForLevelAction(buildingId, levelId));
      }
    }
  }, [levelPhotoPlan]);

  React.useEffect(() => {
    if (typeof apartmentToEditData !== 'undefined') {
      if (apartmentToEditData.success) {
        console.log(apartmentToEditData.apartment);
        setApartmentPicked(true);
        apartmentToEditData.apartment.status = Boolean(apartmentToEditData.apartment.status);
        apartmentToEditData.apartment.galleryPhotosToDelete = [];
        apartmentToEditData.apartment.orientation = apartmentToEditData?.apartment?.orientation?.split(",");
        setApartmentData({ ...apartmentToEditData.apartment });
      }
    }
  }, [apartmentToEditData]);

  const getPolygonString = (polygon) => {
    let polygonString = '';
    for (let i = 0; i < polygon.length; i++) {
      const temp = polygon[i].x.toString() + ',' + polygon[i].y.toString() + ' ';
      polygonString += temp;
    };
    return polygonString;
  };

  const ShowLoading = () => {
    return (
      <div className={classes.loading}>
        <CircularProgress color='warning' thickness={4} size={300} />
      </div>
    )
  };

  const ShowError = () => {
    return (
      <div className={classes.error}>
        <Alert severity="error">
          <AlertTitle>Greška</AlertTitle>
          Neuspješno prikazivanje stanova
        </Alert>
      </div>
    )
  };

  const handleChange = (e) => {
    setApartmentData({
      ...apartmentData,
      [e.target.name]: e.target.value
    });
  };

  const handleEditApartment = (e) => {
    e.preventDefault();
    apartmentData.gallery = apartmentData.gallery.filter(photo => photo.photoId === null);
    console.log(apartmentData);
    dispatch(updateApartmentAction(apartmentData));
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      }
    });
  };

  const ShowUpdateError = () => {
    delete apartmentsForLevel?.apartmentUpdate;
    return (
      <div style={{ marginBottom: '20px' }}>
        <Alert severity="error">
          <AlertTitle>Greška</AlertTitle>
          Neuspješno azuriranje stana
        </Alert>
      </div>
    )
  };

  const ShowUpdateSuccess = () => {
    delete apartmentsForLevel?.apartmentUpdate;
    return (
      <div style={{ marginBottom: '20px' }}>
        <Alert severity="success">
          <AlertTitle>Ok</AlertTitle>
          Stan azuriran
        </Alert>
      </div>
    )
  };

  const SvgPolygon = () => {
    return (
      <>
        <svg
          // height={643}
          // width={1133}
          style={{ position: 'relative' }}
          viewBox={isMobile ? "0 0 1133 643" : "0 0 1133 643"}
          className={classes.svg}
        >
          {
            apartmentsForLevel.apartments.map((apartment, index) => {
              return (
                <polygon
                  className={classes.polygonApt}
                  key={index}
                  points={getPolygonString(apartment.polygon)}
                  style={{ fill: 'rgba(129,215,66,0.3)', strokeWidth: '1' }}
                />
              )
            })
          }
        </svg>
        {
          apartmentsForLevel.apartments.map((apartment, index) => {
            return (
              <div style={{
                position: 'absolute',
                left: `${apartment.polygon[0].x}px`,
                top: `${apartment.polygon[0].y}px`,
                backgroundColor: 'yellow',
                padding: '5px'
              }}>
                <IconButton
                  onClick={() => {
                    dispatch(getApartmentToEditAction(apartment.id));
                  }}
                  size='small'
                  color='success'> <EditIcon /> </IconButton>
                <IconButton
                  onClick={() => {
                    setShowDeletionDialog(true);
                    setDeletionDialogData({
                      type: 2,
                      ...apartment
                    });
                  }}
                  size='small'
                  color='error'> <DeleteIcon /> </IconButton>
              </div>
            )
          })
        }
      </>
    );
  };

  const BuildingAndLevel = () => {
    return (
      <>

        <div className={classes.buildingAndLevel}>
          <div className={classes.buildingAndLevelSubDiv}>
            <Typography variant='h4'>{apartmentsForLevel.buildingName}</Typography>
            <Typography variant='subtitle1'>Zgrada</Typography>
          </div>
          <div className={classes.buildingAndLevelSubDiv}>
            <Typography variant='h4'>{apartmentsForLevel.levelNum}</Typography>
            <Typography variant='subtitle1'>Sprat</Typography>
          </div>
        </div>

      </>
    );
  };

  return (

    <div className={classes.addBuildingSection}>
      <div className={classes.addBuildingForm}>
        <Typography variant='h5' style={{ color: 'black', marginBottom: '20px', textAlign: 'center' }} >
          Edit stanova na {apartmentsForLevel?.levelNum}. spratu u zgradi {apartmentsForLevel?.buildingName}
        </Typography>
        {
          typeof apartmentsForLevel !== 'undefined'
            ?
            apartmentsForLevel.apartmentUpdate
              ?
              apartmentsForLevel.success
                ?
                <ShowUpdateSuccess />
                :
                <ShowUpdateError />
              :
              null
            :
            null
        }
        <form onSubmit={handleEditApartment} className={classes.apartmentForm}>
          <Grid container spacing={3}>

            <Grid item xs={12} md={12}>
              <Button
                disabled={!apartmentPicked}
                onClick={() => { setShowEditApartmentPolygon(true); }}
                variant='outlined'
              >
                Promijeni poligon
              </Button>
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={!apartmentPicked}
                fullWidth
                variant="outlined"
                required
                label="Broj stana"
                name="number"
                type="text"
                value={apartmentData.number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tip</InputLabel>
                <Select
                  disabled={!apartmentPicked}
                  labelId="demo-simple-select-label"
                  value={apartmentData.type}
                  label="Tip"
                  name='type'
                  onChange={handleChange}
                >
                  {
                    types.map((type, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={type.value}>
                          {type.name}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Orijentacija</InputLabel>
                <Select
                  multiple
                  disabled={!apartmentPicked}
                  labelId="demo-simple-select-label"
                  value={apartmentData.orientation}
                  label="Orijentacija"
                  name='orientation'
                  onChange={handleChange}
                >
                  {
                    orientations.map((type, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={type}>
                          {type}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={!apartmentPicked}
                fullWidth
                variant="outlined"
                required
                label="Povrsina"
                name="area"
                type="string"
                value={apartmentData.area}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={!apartmentPicked}
                fullWidth
                variant="outlined"
                required
                label="Cijena"
                name="price"
                type="string"
                value={apartmentData.price}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  disabled={!apartmentPicked}
                  labelId="demo-simple-select-label"
                  value={apartmentData.status}
                  label="Status"
                  name='status'
                  onChange={handleChange}
                >
                  <MenuItem value={false} >Slobodan</MenuItem>
                  <MenuItem value={true} >Prodat</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <Typography style={{ marginRight: '10px' }} variant='h5'>Galerija</Typography>
              <Button
                onClick={() => { setShowEditGallery(true); }}
                disabled={!apartmentPicked}
                fullWidth
                disableElevation
                variant="contained"
                component="label"
                color={apartmentData?.gallery.length > 0 ? 'success' : 'inherit'}
              >
                <VisibilityIcon fontSize='small' />
              </Button>
            </Grid>
            <Grid item md={6}>
              <Typography style={{ marginRight: '10px' }} variant='h5'>Tlocrt</Typography>
              <Button
                onClick={() => { setShowEditOtherPhotos(true); }}
                disabled={!apartmentPicked}
                fullWidth
                disableElevation
                variant="contained"
                component="label"
                color={apartmentData.plan_photo ? 'success' : 'inherit'}
              >
                <VisibilityIcon fontSize='small' />

              </Button>
            </Grid>
            <Grid item md={6}>
              <Typography style={{ marginRight: '10px' }} variant='h5'>Orbital</Typography>
              <Button
                onClick={() => { setShowEditOtherPhotos(true); }}
                disabled={!apartmentPicked}
                fullWidth
                disableElevation
                variant="contained"
                component="label"
                color={apartmentData.orbital_view ? 'success' : 'inherit'}

              >
                <VisibilityIcon fontSize='small' />
              </Button>
            </Grid>
            <Grid item md={6}>
              <Typography style={{ marginRight: '10px' }} variant='h5'>360&deg;</Typography>
              <Button
                onClick={() => { setShowEditOtherPhotos(true); }}
                disabled={!apartmentPicked}
                fullWidth
                disableElevation
                variant="contained"
                component="label"
                color={apartmentData.three_sixty_view ? 'success' : 'inherit'}

              >
                <VisibilityIcon fontSize='small' />
              </Button>
            </Grid>
            <Grid item md={12}>
              <Typography style={{ marginRight: '10px' }} variant='h5'>Pdf katalog</Typography>
              <Button
                disabled={!apartmentPicked}
                fullWidth
                disableElevation
                variant="contained"
                component="label"
                color={apartmentData.pdf_catalogue ? 'success' : 'inherit'}
              >
                <VisibilityIcon fontSize='small' />
                <input
                  id='dugme4'
                  type="file"
                  onChange={async (e) => {
                    //console.log(e.target.files[0]);
                    setApartmentData({
                      ...apartmentData,
                      pdf_catalogue: {
                        name: e.target.files[0].name,
                        base64Url: await readFileAsBase64(e.target.files[0])
                      }
                    })
                  }}
                  accept='.pdf'
                  hidden
                />
              </Button>
            </Grid>
            <Grid item md={12}>
              <Button disabled={!apartmentPicked} fullWidth variant='contained' type='submit' color='success' >
                Ok
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      {
        planPhoto
          ?
          <div
            style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + planPhoto})` }}
            className={classes.levelDisplayDiv}>
            {
              loading
                ?
                <ShowLoading />
                :
                null
            }
            {
              typeof apartmentsForLevel !== 'undefined'
                ?
                apartmentsForLevel.success
                  ?
                  [
                    <SvgPolygon />,
                    <BuildingAndLevel />,
                    <div className={classes.backDiv}>
                      <Button
                        onClick={() => {
                          navigate(`?buildingId=${buildingId}`);
                          localStorage.setItem('currentDisplay', JSON.stringify(1));
                          setCurrentDisplay(1);
                        }}
                        style={{ backgroundColor: 'black', color: 'white' }}
                        variant='contained'
                        fullWidth
                        size={isMobile ? 'small' : 'large'}
                        disableElevation>
                        nazad na zgradu
                      </Button>
                    </div>
                  ]
                  :
                  <ShowError />
                :
                null
            }
          </div>
          :
          <CircularProgress thickness={5} color='warning' />
      }
      <EditApartmentPolygonDialog
        planPhoto={planPhoto}
        open={showEditApartmentPolygon}
        setOpen={setShowEditApartmentPolygon}
        apartmentData={apartmentData}
        setApartmentData={setApartmentData} />
      <DeleteDialog
        open={showDeletionDialog}
        setOpen={setShowDeletionDialog}
        data={deletionDialogData} />
      <EditApartmentGalleryDialog
        open={showEditGallery}
        setOpen={setShowEditGallery}
        apartmentData={apartmentData}
        setApartmentData={setApartmentData}
        readFileAsBase64={readFileAsBase64} />
      <EditOtherApartmentPhotosDialog
        open={showEditOtherPhotos}
        setOpen={setShowEditOtherPhotos}
        apartmentData={apartmentData}
        setApartmentData={setApartmentData}
        readFileAsBase64={readFileAsBase64} />
    </div>
  )
}

export default EditApartments;