import { Grid, Typography, TextField, Button, LinearProgress } from '@mui/material';
import React from 'react';
import useStyle from './style';
import { AlertTitle, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdminAction, emptyLoginDataAction } from '../actions/admins_action';
import { useNavigate } from 'react-router-dom';

const initialLoginData = {
    email: '',
    password: ''
};

const Login = () => {

    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loginData, setLoginData] = React.useState(initialLoginData);

    const loginSelector = useSelector(state => state.loginState);
    const { loading, adminLoginData } = loginSelector;

    const handleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        dispatch( loginAdminAction(loginData) );
    };

    React.useEffect(()=>{
        if( typeof adminLoginData !== 'undefined' ){
            if( adminLoginData.success ) {
                if( adminLoginData.is_super_admin ){
                    navigate('/super-admin');
                    dispatch(emptyLoginDataAction());
                }
                if( adminLoginData.is_admin ){
                    navigate('/regular-admin');
                    dispatch(emptyLoginDataAction());
                }
            }
        }
    },[adminLoginData]);

    const ShowLoading = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Typography variant='caption'>Prijavljivanje...</Typography>
                <LinearProgress color='primary' />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješna prijava
                </Alert>
            </div>
        )
    };

    return (
        <div className={classes.loginDiv}>
            <div style={{ textAlign: 'center' }}>
                <Typography variant='h4' >
                    Prijava
                </Typography>
            </div>
            <div className={classes.form}>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        typeof adminLoginData !== 'undefined'
                            ?
                            !adminLoginData.success
                                ?
                                 <ShowError />
                                :
                                null
                            :
                            null
                }
                <form onSubmit={handleFormSubmit} >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Email"
                                name="email"
                                type="email"
                                value={loginData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                label="Šifra"
                                name="password"
                                type="password"
                                value={loginData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                fullWidth
                            >
                                Prijavi se
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    )
}

export default Login