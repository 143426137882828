import React from 'react';
import useStyles from './style';
import { Button, Grid, IconButton, TextField, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { updateHeaderDataAction, getHeaderDataAction } from '../actions/admins_action';

const initialHeaderData = {
    logo: '',
    logoName: '',
    complex: '',
    complexName: '',
    phoneNumber: '',
    emailAddress: '',
    pageTitle: '',
    pageDescription: ''
};

const EditLogoAndPhone = ({ currentSection }) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const inputRefLogo = React.useRef();

    const inputRefComplex = React.useRef();

    const [headerData, setHeaderData] = React.useState(initialHeaderData);

    const [showLogo, setShowLogo] = React.useState(false);

    const [showComplex, setShowComplex] = React.useState(false);

    const headerDataSelector = useSelector(state => state.headerDataState);
    const { headerData: headerDataResponse } = headerDataSelector;

    React.useEffect(() => {
        dispatch(getHeaderDataAction());
    }, [currentSection]);

    React.useEffect(() => {
        if (typeof headerDataResponse !== 'undefined') {
            if (headerDataResponse.success) {
                setHeaderData({ ...headerDataResponse });
            }
        };
    }, [headerDataResponse]);

    const handleChange = (e) => {
        setHeaderData({
            ...headerData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateHeaderDataAction(headerData));
    };

    const handleLogoChange = async (e) => {
        if (e.target.files[0]) {
            setHeaderData({
                ...headerData,
                logo: await readFileAsBase64(e.target.files[0]),
                logoName: e.target.files[0].name
            });
        };
    };

    const handleComplexChange = async (e) => {
        if (e.target.files[0]) {
            setHeaderData({
                ...headerData,
                complex: await readFileAsBase64(e.target.files[0]),
                complexName: e.target.files[0].name
            });
        };
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const ShowError = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                </Alert>
            </div>
        )
    };

    const ShowSuccess = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="success">
                    <AlertTitle>Uspješno</AlertTitle>
                </Alert>
            </div>
        )
    };

    return (
        <div className={classes.editLogoAndPhone}>
            <form onSubmit={handleSubmit}>
                {
                    typeof headerDataResponse !== 'undefined'
                        ?
                        headerDataResponse.update
                            ?
                            headerDataResponse.success
                                ?
                                <ShowSuccess />
                                :
                                <ShowError />
                            :
                            null
                        :
                        null
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Broj telefona"
                            name="phoneNumber"
                            type="text"
                            value={headerData.phoneNumber}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Email"
                            name="emailAddress"
                            type="email"
                            value={headerData.emailAddress}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Naslov stranice"
                            name="pageTitle"
                            type="text"
                            value={headerData.pageTitle}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Opis stranice"
                            name="pageDescription"
                            type="text"
                            value={headerData.pageDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            onClick={() => { inputRefLogo.current.click(); }}
                            style={{ marginRight: '10px' }}
                            variant='outlined'
                            color='primary'
                            disableElevation>
                            Logo
                        </Button>
                        <IconButton color='secondary' onClick={() => { setShowLogo(true); }}>
                            <EyeIcon />
                        </IconButton>
                        <div className={classes.showLogo} style={{ display: showLogo ? 'block' : 'none' }}>
                            <img
                                src={headerData.logoName ? headerData.logo : process.env.REACT_APP_UPLOADS_PATH + '/logo/logo.png'}
                                style={{ width: '100%' }} alt='Logo' />
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    border: '1px solid black'
                                }}
                                color='error'
                                onClick={() => { setShowLogo(false); }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            onClick={() => { inputRefComplex.current.click(); }}
                            style={{ marginRight: '10px' }}
                            variant='outlined'
                            color='primary'
                            disableElevation>
                            Kompleks
                        </Button>
                        <IconButton color='secondary' onClick={() => { setShowComplex(true); }}>
                            <EyeIcon />
                        </IconButton>
                        <div className={classes.showLogo} style={{ display: showComplex ? 'block' : 'none' }}>
                            <img
                                src={headerData.complexName ? headerData.complex : process.env.REACT_APP_UPLOADS_PATH + headerData.complex}
                                style={{ width: '100%' }} alt='Logo' />
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    border: '1px solid black'
                                }}
                                color='error'
                                onClick={() => { setShowComplex(false); }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button type='submit' variant='contained' color='success' fullWidth disableElevation>
                            Ok
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <input
                id='dugme'
                ref={inputRefLogo}
                type="file"
                onChange={handleLogoChange}
                accept='image/*'
                hidden
            />
            <input
                id='dugme'
                ref={inputRefComplex}
                type="file"
                onChange={handleComplexChange}
                accept='image/*'
                hidden
            />
        </div>
    );
};

export default EditLogoAndPhone;