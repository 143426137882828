import * as api from '../api/api';

export const loginAdminAction = (loginData) => async (dispatch) => {
    try {

        dispatch({ type : 'REQUEST_LOGIN_ADMIN' });

        const { data } = await api.loginAdmin(loginData);

        dispatch({
            type : 'SUCCESS_LOGIN_ADMIN',
            payload : data
        });
        
    } catch (error) {
        console.log('Error in logging in admin: ',error.message);
        dispatch({
            type : 'ERROR_LOGIN_ADMIN',
            payload : error.response.data
        });
    }
};

export const emptyLoginDataAction = () => async (dispatch) => {
    dispatch({
        type : 'EMPTY_LOGIN_ADMIN_DATA'
    });
};

export const checkJwtTokenAction = () => async (dispatch) => {
    try {

        const { data } = await api.checkJwtToken();

        dispatch({
            type: 'SUCCESS_CHECK_JWT',
            payload: data
        });

    } catch (error) {
        console.log('Error in checking jwt token: ', error.message);
        dispatch({
            type: 'ERROR_CHECK_JWT',
            payload: error.response.data
        });
    };
};


export const emptyJwtTokenAction = () => async (dispatch) => {
    dispatch({
        type : 'EMPTY_CHECK_JWT'
    });
};

export const logoutAdminAction = () => async (dispatch) => {
    try {

        const { data } = await api.logout();

        dispatch({
            type: 'SUCCESS_LOGOUT_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in logging out admin: ', error.message);
        dispatch({
            type: 'ERROR_LOGOUT_ADMIN',
            payload: error.response.data
        });
    };
};

export const checkSuperAdminAction = () => async (dispatch) => {
    try {

        const { data } = await api.checkSuperAdmin();

        dispatch({
            type: 'SUCCESS_CHECK_SUPER_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in checking super admin: ', error.message);
        dispatch({
            type: 'ERROR_CHECK_SUPER_ADMIN',
            payload: error.response.data
        });
    };
};

export const checkRegularAdminAction = () => async (dispatch) => {
    try {

        const { data } = await api.checkRegularAdmin();

        dispatch({
            type: 'SUCCESS_CHECK_REGULAR_ADMIN',
            payload: data
        });

    } catch (error) {
        console.log('Error in checking regular admin: ', error.message);
        dispatch({
            type: 'ERROR_CHECK_REGULAR_ADMIN',
            payload: error.response.data
        });
    };
};

export const getHeaderDataAction = () => async (dispatch) => {
    try {

        const { data } = await api.getHeaderData();

        dispatch({
            type : 'SUCCESS_GET_HEADER_DATA',
            payload : data
        });
        
    } catch (error) {
        console.log('Error in getting header data: ',error.message);
        dispatch({
            type : 'ERROR_GET_HEADER_DATA',
            payload : error.response.data
        });
    }
};

export const updateHeaderDataAction = (headerData) => async (dispatch) => {
    try {

        const { data } = await api.updateHeaderData(headerData);

        dispatch({
            type : 'SUCCESS_GET_HEADER_DATA',
            payload : data
        });
        
    } catch (error) {
        console.log('Error in getting header data: ',error.message);
        dispatch({
            type : 'ERROR_GET_HEADER_DATA',
            payload : error.response.data
        });
    }
};

export const getComplexPhotoAction = () => async (dispatch) => {
    try {

        const { data } = await api.getComplexPhoto();

        dispatch({
            type : 'SUCCESS_GET_COMPLEX_PHOTO',
            payload : data
        });
        
    } catch (error) {
        console.log('Error in getting complex photo: ',error.message);
        dispatch({
            type : 'ERROR_GET_COMPLEX_PHOTO',
            payload : error.response.data
        });
    }
};