import React from 'react';
import { TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useStyles from './style';
import { searchApartmentsAction, getApartmentsForRegularAdminAction } from '../actions/apartments_action';

const SearchField = ({ apartmentsData, dispatch }) => {

    const classes = useStyles();

    const [searchQuery, setSearchQuery] = React.useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery.length > 0) {
            dispatch(searchApartmentsAction(searchQuery));
        } else {
            dispatch(getApartmentsForRegularAdminAction());
        }
    };

    return (
        <form onSubmit={handleSearch}>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <TextField
                        className={classes.searchField}
                        disabled={!apartmentsData?.success}
                        fullWidth
                        variant="outlined"
                        id="search"
                        label="Pretraga stanova"
                        name="search"
                        type="text"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type='submit' size='small'>
                                        <Search type='submit' fontSize='large' />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                </Grid>
            </Grid>
        </form>
    )
};

export default SearchField