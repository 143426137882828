import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    navBar: {
        padding: '12px',
        borderBottom: '2px solid #D2D7D3',
        [theme.breakpoints.down('md')]: {
            padding: '0px'
        }
    },
    navToolBar: {
        color: 'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',

        },
        [theme.breakpoints.up('960')]: {
            color: 'black',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    },
    logoMobile: {
        //border : '2px solid gray',
        // textAlign: 'center',
        // width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    phoneDesktop: {
        [theme.breakpoints.down('960')]: {
            display: 'none'
        }
    },
    phoneAndMenu: {
        //border : '1px solid green',
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    logoAndOptions: {
        //border : '1px solid black',
        width: '52%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('960')]: {
            display: 'none'
        }
    },
    options: {
        //border : '1px solid red',
        width: '49%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    navBarLanguagePicker: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    logo: {
        cursor: 'pointer'
    },
    singleOption: {
        transition: '0.8s',
        cursor: 'pointer',
        '&:hover': {
            color: '#0074b9'
        },
    },
    modal: {
        position: 'fixed',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        transition: 'all 0.4s ease',
        backgroundColor: 'rgba(17, 17, 17, 0.9)',
        zIndex: '3',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    languagesModal : {
        //border : '1px solid red',
        display : 'grid',
        gridTemplateColumns : '1fr 1fr 1fr',
        gap : '10px',
        //width : '100%',
        //margin : 'auto',
        marginTop : '20px'
    },
    languageModalItem : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'center',
        alignItems : 'center',
        padding : '6px'
    },
    mobileLogoAndMenu: {
        backgroundColor: '#1e5180',
        width: '100vw',
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    mobilePhoneNumber: {
        //border: '2px solid red',
        textAlign: 'center',
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));

export default useStyles;