import { combineReducers } from "redux";

import {
        loginAdminReducer,
        checkJwtTokenReducer,
        logoutUserReducer,
        checkRegularAdminReducer,
        checkSuperAdminReducer,
        getHeaderDataReducer,
        getComplexPhotoReducer
} from "./admins_reducer";

import { addNewBuildingReducer, getBuildingPhotoReducer, getBuildingsReducer } from './buildings_reducer';

import {
        addNewLevelReducer,
        getBuildingLevelsReducer,
        getLevelPlanPhotoReducer,
} from "./levels_reducer";

import {
        addNewApartmentReducer,
        getApartmentsForLevelReducer,
        getApartmentReducer,
        sendPlanToEmailReducer,
        getParametersReducer,
        getApartmentsForRegularAdminReducer,
        sendQuestionReducer,
        getTableApartmentsReducer,
        getApartmentToEditReducer
} from './apartments_reducer';

export default combineReducers({
        loginState: loginAdminReducer,
        checkJwtTokenState: checkJwtTokenReducer,
        logoutAdminState: logoutUserReducer,
        checkSuperAdminState: checkSuperAdminReducer,
        checkRegularAdminState: checkRegularAdminReducer,
        addNewBuildingState: addNewBuildingReducer,
        getBuildingsState: getBuildingsReducer,
        addNewLevelState: addNewLevelReducer,
        buildingLevelsState: getBuildingLevelsReducer,
        levelPlanPhotoState: getLevelPlanPhotoReducer,
        addNewApartmentState: addNewApartmentReducer,
        apartmentsForLevelState: getApartmentsForLevelReducer,
        getApartmentState: getApartmentReducer,
        sendPlanToEmailState : sendPlanToEmailReducer,
        sendQuestionState : sendQuestionReducer,
        parametersState : getParametersReducer,
        apartmentsRegularAdminState : getApartmentsForRegularAdminReducer,
        tableApartmentsState : getTableApartmentsReducer,
        getApartmentToEditState : getApartmentToEditReducer,
        headerDataState : getHeaderDataReducer,
        buildingPhotoState : getBuildingPhotoReducer,
        getComplexPhotoState : getComplexPhotoReducer
});