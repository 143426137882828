import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    complexDisplayDiv: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '643px',
        width: '1133px',
        overflow: 'hidden',
        //backgroundSize : '1384px 810px',
        //transform: 'scale(1.1)',
        //backgroundSize: '1384px 800px',
        //transform: 'translate(-192px, -64px) scale(0.7775)',
        //transformOrigin: 'left top',
        [theme.breakpoints.down('960')]: {
            backgroundSize: 'cover',
            width : '100%',
            height : '100%',
            backgroundPosition : 'center center',
            order : '1'
            //transform: 'translate(0px, 0px) scale(0.9)',
            //overflow: 'scroll',
        }
    },
    svg: {
        position: 'relative',
        // height: '643px',
        // width: '1133px',
        //border: '3px solid green',
        // display : 'block',
        // margin : "auto",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            transform: 'scale(1.03)'
        }
    },
    polygon: {
        '&:hover': {
            fill: 'rgba(255,255,255,0.25) !important'
        }
    },
    buildingNameMarker: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4B77BE',
        color: 'white',
        width: '75px',
        height: '75px',
    },
    buildingAvailableMarker: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        color: 'black',
        width: '75px',
        height: '75px',
        zIndex: '20'

    },
    backgroundBlur: {
        border: '10px solid red',
        //filter : 'brightness(40%)',
        position: "absolute",
        left: "0",
        right: '0',
        top: '0',
        bottom: "0"
    },
    loading: {
        //filter : 'blur(-2px)',
        position: 'absolute',
        left: '50%',
        top: '26%',
        transform: "translate(-50%, 0)",
        zIndex: '50'
    },
    error: {
        position: 'absolute',
        left: '50%',
        top: '26%',
        transform: "translate(-50%, 0)",
        zIndex: '50'
    },
    textSvg : {
        fill : 'white',
        fontSize : '50px',
        //fontFamily : "'Akshar', sans-serif",
    },
    textSvgTwo : {
        fill : 'black',
        fontSize : '50px',
        //fontFamily : "'Akshar', sans-serif",
    }
}));

export default useStyles;