import { Typography, CircularProgress, Grid, TextField, Button, IconButton } from '@mui/material';
import React from 'react';
import useStyle from './style';
import photo from '../assets/photos/kvart.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildingsAction } from '../actions/buildings_action';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import noPhoto from '../assets/photos/noPhoto.jpeg';
import DeleteDialog from './DeleteDialog';
import EditBuildingPolygonDialog from './EditBuildingPolygonDialog';
import { updateBuildingAction } from '../actions/buildings_action';
import { getComplexPhotoAction } from '../actions/admins_action';


const initialBuildingData = {
    name: '',
    numOfLevels: '',
    id: '',
    building_photo: '',
    new_building_photo_name: '',
    building_photo_mobile: '',
    new_building_photo_name_mobile: ''
};

const EditComplex = ({ setCurrentSection }) => {

    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentDisplay, setCurrentDisplay } = useContext(GlobalContext);

    const [previewPhoto, setPreviewPhoto] = React.useState(false);
    const [previewPhotoMobile, setPreviewPhotoMobile] = React.useState(false);

    const [buildingPicked, setBuildingPicked] = React.useState(false);

    const [showDeletionDialog, setShowDeletionDialog] = React.useState(false);
    const [deletionDialogData, setDeletionDialogData] = React.useState({});

    const [showEditBuildingPolygon, setShowEditBuildingPolygon] = React.useState(false);

    const [buildingData, setBuildingData] = React.useState(initialBuildingData);

    const getBuildingsSelector = useSelector(state => state.getBuildingsState);
    const { loading, getBuildingsData } = getBuildingsSelector;

    const [complexPhoto, setComplexPhoto] = React.useState(null);

    const getComplexPhotoSelector = useSelector(state => state.getComplexPhotoState);
    const { complexPhotoData } = getComplexPhotoSelector;

    React.useEffect(() => {
        dispatch(getComplexPhotoAction());
    }, []);

    React.useEffect(() => {
        if (typeof complexPhotoData !== 'undefined') {
            if (complexPhotoData.success) {
                setComplexPhoto(complexPhotoData.complexPhoto);
                dispatch(getBuildingsAction());
            }
        }
    }, [complexPhotoData]);

    const handleChange = (e) => {
        setBuildingData({
            ...buildingData,
            [e.target.name]: e.target.value
        })
    };

    const getPolygonString = (polygon) => {
        let polygonString = '';
        for (let i = 0; i < polygon.length; i++) {
            const temp = polygon[i].x.toString() + ',' + polygon[i].y.toString() + ' ';
            polygonString += temp;
        };
        return polygonString;
    };

    const handleImageChange = async (e) => {
        if (e.target.files) {
            setBuildingData({
                ...buildingData,
                building_photo: await readFileAsBase64(e.target.files[0]),
                new_building_photo_name: e.target.files[0].name
            });
        }
    };

    const handleImageChangeMobile = async (e) => {
        if (e.target.files) {
            setBuildingData({
                ...buildingData,
                building_photo_mobile: await readFileAsBase64(e.target.files[0]),
                new_building_photo_name_mobile: e.target.files[0].name
            });
        }
    };

    const handleEditBuilding = (e) => {
        e.preventDefault();
        dispatch(updateBuildingAction({
            name: buildingData.name,
            numOfLevels: buildingData.numOfLevels,
            polygon: buildingData.polygon,
            id: buildingData.id,
            building_photo: buildingData.building_photo,
            new_building_photo_name: buildingData.new_building_photo_name,
            building_photo_mobile: buildingData.building_photo_mobile,
            new_building_photo_name_mobile: buildingData.new_building_photo_name_mobile
        }));
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            }
        });
    };

    const ShowLoading = () => {
        return (
            <div className={classes.loading}>
                <CircularProgress color='warning' thickness={4} size={300} />
            </div>
        );
    };

    const ShowError = () => {
        return (
            <div className={classes.error}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje zgrada
                </Alert>
            </div>
        );
    };

    const ShowUpdateError = () => {
        delete getBuildingsData?.buildingUpdate;
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno azuriranje zgrade
                </Alert>
            </div>
        )
    };

    const ShowUpdateSuccess = () => {
        delete getBuildingsData?.buildingUpdate;
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="success">
                    <AlertTitle>Ok</AlertTitle>
                    Zgrada azurirana
                </Alert>
            </div>
        )
    };

    const SvgPolygons = () => {
        return (
            <>
                <svg
                    viewBox="0 0 1133 643"
                    className={classes.svg}
                >
                    {
                        getBuildingsData.buildings.map((building, index) => {
                            return (
                                <polygon
                                    onClick={() => {
                                        setCurrentSection(4);
                                        navigate(`?buildingId=${building.id}`);
                                        // localStorage.setItem('currentDisplay', JSON.stringify(1));
                                        // setCurrentDisplay(1);
                                    }}
                                    className={classes.polygon}
                                    key={index}
                                    points={getPolygonString(building.polygon)}
                                    style={{ fill: 'rgba(129,215,66,0.3)', strokeWidth: '1', cursor: 'pointer' }}
                                />
                            )
                        })
                    }

                </svg>
                {
                    getBuildingsData.buildings.map((building, index) => {
                        return (
                            <div
                                className={classes.buildingNameMarker}
                                style={{
                                    left: `${building.polygon[0].x}px`,
                                    top: `${building.polygon[0].y - 70}px`
                                }}>
                                <Typography variant='h5' style={{ marginBottom: '-3px' }}>{building.name}</Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '7px'
                                }}>
                                    <IconButton
                                        onClick={() => {
                                            setBuildingData({
                                                numOfLevels: building.num_of_levels,
                                                id: building.id,
                                                ...building
                                            });
                                            setBuildingPicked(true);
                                        }}
                                        size='small'
                                        color='success'
                                        style={{ backgroundColor: 'white' }}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setShowDeletionDialog(true);
                                            setDeletionDialogData({
                                                type: 0,
                                                ...building
                                            });
                                        }}
                                        size='small'
                                        color='error'
                                        style={{ backgroundColor: 'white' }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })
                }

            </>
        )
    }

    return (
        <div className={classes.addBuildingSection}>
            <div className={classes.addBuildingForm}>
                <Typography variant='h5' style={{ color: 'black', marginBottom: '20px', textAlign: 'center' }} >Edit zgrade {buildingData.name}</Typography>
                {
                    typeof getBuildingsData !== 'undefined'
                        ?
                        getBuildingsData.buildingUpdate
                            ?
                            getBuildingsData.success
                                ?
                                <ShowUpdateSuccess />
                                :
                                <ShowUpdateError />
                            :
                            null
                        :
                        null
                }
                <form onSubmit={handleEditBuilding} className={classes.form}>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12}>
                            <TextField
                                disabled={!buildingPicked}
                                fullWidth
                                variant="outlined"
                                required
                                label="Ime"
                                name="name"
                                type="text"
                                value={buildingData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                disabled={!buildingPicked}
                                fullWidth
                                variant="outlined"
                                required
                                label="Broj spratova"
                                name="numOfLevels"
                                type="number"
                                value={buildingData.numOfLevels}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                            <Button
                                onClick={() => { setPreviewPhoto(true); }}
                                disabled={!buildingPicked}
                                disableElevation
                                fullWidth
                                variant="contained"
                                component="label"
                                color='inherit'
                            >
                                <VisibilityIcon fontSize='small' style={{ marginRight: '5px' }} />
                                Pregledaj sliku
                            </Button>
                            <div className={classes.photoPreview} style={{ display: previewPhoto ? 'block' : 'none' }}>
                                <img
                                    src={buildingData.building_photo ?
                                        buildingData.new_building_photo_name ?
                                            buildingData.building_photo :
                                            process.env.REACT_APP_UPLOADS_PATH + buildingData.building_photo :
                                        noPhoto}
                                    width='410' />
                                <CloseIcon
                                    onClick={() => { setPreviewPhoto(false); }}
                                    color='error'
                                    fontSize='large'
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        cursor: 'pointer'
                                    }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={!buildingPicked}
                                disableElevation
                                fullWidth
                                variant="contained"
                                component="label"
                                color={buildingData?.new_building_photo_name ? 'success' : 'inherit'}
                            >
                                {
                                    buildingData?.new_building_photo_name
                                        ?
                                        <DoneAllIcon fontSize='small' style={{ marginRight: '5px' }} />
                                        :
                                        <AddIcon fontSize='small' style={{ marginRight: '5px' }} />
                                }
                                Promijeni sliku
                                <input
                                    id='dugme'
                                    type="file"
                                    onChange={handleImageChange}
                                    multiple
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        {/* ////////////////////////////////////////////////// */}
                        <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                            <Button
                                onClick={() => { setPreviewPhotoMobile(true); }}
                                disabled={!buildingPicked}
                                disableElevation
                                fullWidth
                                variant="contained"
                                component="label"
                                color='inherit'
                            >
                                <VisibilityIcon fontSize='small' style={{ marginRight: '5px' }} />
                                Slika mobilni
                            </Button>
                            <div className={classes.photoPreview} style={{ display: previewPhotoMobile ? 'block' : 'none' }}>
                                <img
                                    src={buildingData.building_photo_mobile ?
                                        buildingData.new_building_photo_name_mobile ?
                                            buildingData.building_photo_mobile :
                                            process.env.REACT_APP_UPLOADS_PATH + buildingData.building_photo_mobile :
                                        noPhoto}
                                    width='210' />
                                <CloseIcon
                                    onClick={() => { setPreviewPhotoMobile(false); }}
                                    color='error'
                                    fontSize='large'
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        cursor: 'pointer'
                                    }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={!buildingPicked}
                                disableElevation
                                fullWidth
                                variant="contained"
                                component="label"
                                color={buildingData?.new_building_photo_name_mobile ? 'success' : 'inherit'}
                            >
                                {
                                    buildingData?.new_building_photo_name_mobile
                                        ?
                                        <DoneAllIcon fontSize='small' style={{ marginRight: '5px' }} />
                                        :
                                        <AddIcon fontSize='small' style={{ marginRight: '5px' }} />
                                }
                                Promijeni sliku
                                <input
                                    id='dugme'
                                    type="file"
                                    onChange={handleImageChangeMobile}
                                    multiple
                                    accept='image/*'
                                    hidden
                                />
                            </Button>
                        </Grid>
                        {/* ////////////////////////////////////////////////// */}
                        <Grid item xs={12} md={12}>
                            <Button
                                disabled={!buildingPicked}
                                onClick={() => { setShowEditBuildingPolygon(true); }}
                                variant='outlined'
                            >
                                Promijeni poligon
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                loading
                                    ?
                                    <ShowLoading />
                                    :
                                    <Button
                                        disabled={!buildingPicked}
                                        disableElevation
                                        fullWidth
                                        variant='contained'
                                        color='success'
                                        type='submit'
                                    >
                                        Ok
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div
                style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + complexPhoto})`, filter: `blur( ${loading ? '2px' : '0px'} )` }}
                className={classes.addBuildingPhoto}>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        null
                }
                {
                    typeof getBuildingsData !== 'undefined'
                        ?
                        getBuildingsData.success
                            ?
                            <SvgPolygons />
                            :
                            <ShowError />
                        :
                        null
                }

            </div>
            <DeleteDialog
                open={showDeletionDialog}
                setOpen={setShowDeletionDialog}
                data={deletionDialogData} />
            <EditBuildingPolygonDialog
                complexPhoto={complexPhoto}
                open={showEditBuildingPolygon}
                setOpen={setShowEditBuildingPolygon}
                buildingData={buildingData}
                setBuildingData={setBuildingData} />
        </div>
    );
};

export default EditComplex;
