import { Typography, LinearProgress, Alert, AlertTitle, Button } from '@mui/material';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
///////////////
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
////////////////////////////////////
import { getTableApartmentsAction } from '../actions/apartments_action';
import ApartmentDialog from './ApartmentDialog';
import { t } from 'i18next';

const TableDisplay = () => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const [showDialog, setShowDialog] = React.useState(false);
    const [dialogInfo, setDialogInfo] = React.useState({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const tableApartmentsSelector = useSelector(state => state.tableApartmentsState);
    const { loading, tableApartmentsData } = tableApartmentsSelector;

    console.log(tableApartmentsData);

    // React.useEffect(() => {
    //     dispatch(getTableApartmentsAction());
    // }, []);

    const ShowLoading = () => {
        return (
            <div style={{ margin: '5%' }}>
                <Typography variant='caption'>Ucitavanje...</Typography>
                <LinearProgress color='primary' />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje tabele
                </Alert>
            </div>
        )
    };

    const getNumOfRooms = (type) => {
        switch (type) {
            case 1: return t("Garsonjera");
            case 2: return t("Jednosoban");
            case 3: return t("Dvosoban");
            case 4: return t("Trosoban");
            case 5: return 'Cetvorosoban';
            case 6: return 'Petosoban';
            default: return 0;
        }
    };

    const downloadPlanPhoto = (photo) => {
        if (photo) {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.setAttribute('target', '_blank');
            a.href = process.env.REACT_APP_UPLOADS_PATH + photo;
            a.click();
            a.remove();
        } else {
            alert('Tlocrt nije dostupan')
        }
    };

    return (
        <div className={classes.tableDiv}>
            {
                loading
                    ?
                    <ShowLoading />
                    :
                    null
            }
            {
                typeof tableApartmentsData !== 'undefined'
                    ?
                    tableApartmentsData.success
                        ?
                        <div className={classes.table}>
                            <TableContainer component={Paper} elevation={1} style={{ borderRadius: '0px' }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '18px' }}>{t("Broj")}</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '18px' }}>{t("Sprat")}</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '18px' }}>{t("Tip")}</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '18px' }}>{t("Površina")}</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '18px' }}>{t("Cijena")}</TableCell>
                                            {
                                                !isMobile
                                                    ?
                                                    [<TableCell style={{ fontWeight: 'bold', fontSize: '18px' }}>{t("Status")}</TableCell>,
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '18px' }}>{t("Tlocrt stana")}</TableCell>]
                                                    :
                                                    null
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableApartmentsData.apartments.map((row, index) => (
                                            <TableRow
                                                onClick={() => {
                                                    setShowDialog(true);
                                                    setDialogInfo({ ...row });
                                                }}
                                                hover
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                            >
                                                <TableCell>{row.number}</TableCell>
                                                <TableCell>{row.level_number}</TableCell>
                                                <TableCell>{getNumOfRooms(row.type)}</TableCell>
                                                <TableCell>{+row.area} &#13217;</TableCell>
                                                <TableCell>{+row.price} &euro;</TableCell>
                                                {
                                                    !isMobile
                                                        ?
                                                        [
                                                            <TableCell>
                                                                {row.status ? 'Prodat' : 'Slobodan'}
                                                            </TableCell>,
                                                            <TableCell>
                                                                <Button
                                                                    style={{ borderRadius: '0px' }}
                                                                    size='small'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        downloadPlanPhoto(row.photo);
                                                                    }}
                                                                    variant='contained'
                                                                    disableElevation
                                                                    fullWidth>
                                                                    {t("Preuzmi")}
                                                                </Button>
                                                            </TableCell>]
                                                        :
                                                        null
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        :
                        <ShowError />
                    :
                    null
            }
            <ApartmentDialog
                show={showDialog}
                getNumOfRooms={getNumOfRooms}
                setShow={setShowDialog}
                info={dialogInfo}
                downloadPlanPhoto={downloadPlanPhoto}
                t={t} />
        </div>
    );
};

export default TableDisplay;