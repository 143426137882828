import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    buildingDisplayDiv: {
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center',
        height: '643px',
        width: '1133px',
        overflow: 'hidden',
         [theme.breakpoints.down('960')]: {
            // backgroundSize: '100% 100%',
            // order : '1',
            // width: '100%',
            // height : '100%'
            //height : '460px'
            backgroundSize : 'cover',
            backgroundPosition: 'center center',
            width : "100%",
            height : '100%',
            //border : '3px solid black'
        }
    },
    svg: {
        position: 'relative',
        // height: '643px',
        // width: '1133px',
        //border: '3px solid green',
        // display : 'block',
        // margin : "auto",
        [theme.breakpoints.down('xs')]: {
            width : '100%',
            height : '100%',
            transform : 'scale(1.02)'
        }
    },
    polygon: {
        '&:hover': {
            fill: 'rgba(255,255,255,0.25) !important'
        }
    },
    textSvg : {
        fill : 'white',
        fontSize : '60px',
        //fontFamily : "'Akshar', sans-serif",

    },
    loading: {
        //filter : 'blur(-2px)',
        position: 'absolute',
        left: '50%',
        top: '26%',
        transform: "translate(-50%, 0)",
        zIndex: '50'
    },
    buildingName: {
        position: 'absolute',
        top: '40px',
        right: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: 'black',
        backgroundColor: 'white',
        width: '130px',
        height: '130px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            top : '10px',
            right : '10px',
            width: '90px',
            height: '90px'
        }
    },
    levelMarker: {
        width: '180px',
        height: '80px',
        position: 'absolute',
        gridTemplateColumns: "1fr 1fr",
    },
    levelNumber: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4B77BE',
        color: 'white',
    },
    availableApartments: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        backgroundColor: 'white'
    },
    backDiv: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        backgroundColor: 'white',
        color: 'black',
        [theme.breakpoints.down('xs')]: {
            top : '5px',
            left : '5px'
        }
    }
}));

export default useStyles;