import React from 'react';
import useStyles from './style';
import SidePanel from '../Side Panel/SidePanel';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ComplexDisplay from '../Complex Display/ComplexDisplay';
import BuildingDisplay from '../Building Display/BuildingDisplay';
import LevelDisplay from '../Level Display/LevelDisplay';
import ApartmentDisplay from '../Apartment Display/ApartmentDisplay';
import TableDisplay from '../Table Display/TableDisplay';
import { useContext } from 'react';
import { GlobalContext } from '../App';
import { useLocation } from 'react-router-dom';

const MainView = () => {

    const { currentDisplay, setCurrentDisplay } = useContext(GlobalContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('960'), { noSsr: true });

    const classes = useStyles();

    const location = useLocation();

    React.useEffect(() => {
        if (location.search.length === 0 && currentDisplay !== 0 && currentDisplay !== 4) {
            setCurrentDisplay(0);
        }
    }, [location]);

    const showCurrentDisplay = () => {
        switch (currentDisplay) {
            case 0: return <ComplexDisplay />
            case 1: return <BuildingDisplay />
            case 2: return <LevelDisplay />
            case 3: return <ApartmentDisplay />
            case 4: return <TableDisplay />
            default: return <ComplexDisplay />
        }
    };

    return (
        <div
            className={classes.mainViewDiv}
            style={{
                gridTemplateColumns :
                isMobile ? '1fr' : 
                currentDisplay !== 3 ? '1fr 3fr' : '1fr'
            }}>
            {
                currentDisplay !== 3
                    ?
                    <SidePanel />
                    :
                    null
            }
            <div>
            {
                showCurrentDisplay()
            }
            </div>
        </div>
    );
};

export default MainView;