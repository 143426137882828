import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    loginDiv : {
        width : '70%',
        margin : 'auto',
        marginTop : '4%',
        [theme.breakpoints.down('xs')]: {
            marginTop : '10%',
            width : '98%'
        }
    },
    form : {
        width : '30%',
        margin : 'auto',
        marginTop : '2%',
        padding : '5px',
        [theme.breakpoints.down('xs')]: {
            marginTop : '4%',
            width : '80%'
        }
    },
    loading : {
        marginBottom : '10%'
    }
}));

export default useStyles;