import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import React from 'react';
import useStyles from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditLevelPolygonDialog = ({ 
    open, 
    setOpen, 
    levelData, 
    setLevelData, 
    buildingPhoto,
    buildingPhotoMobile,
    buildingPhotoType,
    setBuildingPhotoType
}) => {

    const photo = buildingPhotoType === 'desktop' ? buildingPhoto : buildingPhotoMobile;

    const classes = useStyles();

    const [polygonLines, setPolygonLines] = React.useState([]);

    const [firstCircle, setFirstCircle] = React.useState(null);

    const [isPathClosed, setIsPathClosed] = React.useState(false);

    const canvasRef = React.useRef();

    React.useEffect(() => {
        if (polygonLines.length > 0 && !isPathClosed) {
            drawPolygon();
        }
    }, [polygonLines]);

    const handleCanvasClick = (e) => {

        if (isPathClosed) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        const rect = e.target.getBoundingClientRect();

        const context = canvasRef.current.getContext("2d");

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPolygonLines([
            ...polygonLines,
            { x, y }
        ]);

        context.beginPath();
        context.strokeStyle = '#ff0000';
        context.lineWidth = 2;
        context.arc(x, y, 8, 0, 2 * Math.PI);
        context.stroke();

        if (polygonLines.length === 0) {
            setFirstCircle({
                x,
                y,
                r: 12
            });
        }
    };

    const drawPolygon = () => {
        const context = canvasRef.current.getContext("2d");
        context.lineWidth = 2;
        context.fillStyle = 'rgba(129,215,66,0.3)';
        context.strokeStyle = '#ff0000';
        context.beginPath();
        context.moveTo(polygonLines[0].x, polygonLines[0].y);
        for (let index = 1; index < polygonLines.length; index++) {
            context.lineTo(polygonLines[index].x, polygonLines[index].y);
        }
        if (polygonLines.length > 1) {
            const d = Math.sqrt(Math.pow((firstCircle.x - polygonLines[polygonLines.length - 1].x), 2) + Math.pow((firstCircle.y - polygonLines[polygonLines.length - 1].y), 2));
            if (d <= firstCircle.r) {
                setIsPathClosed(true);
                const pl = [...polygonLines];
                pl[pl.length - 1].x = pl[0].x;
                pl[pl.length - 1].y = pl[0].y;
                setPolygonLines([...pl]);
                context.closePath();
                context.fill();
            }
        }
        context.stroke();
    };

    const handleClearCanvas = () => {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setPolygonLines([]);
        setIsPathClosed(false);
    };

    const handleClose = () => {
        setOpen(false);
        setBuildingPhotoType(null);
        setPolygonLines([]);
        setFirstCircle(null);
        setIsPathClosed(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Odustani
                    </Typography>
                    <Button
                        disableElevation
                        color="success"
                        variant='contained'
                        onClick={() => {
                            if (polygonLines.length >= 4) {
                                if(photo === 'desktop'){
                                    setLevelData({
                                        ...levelData,
                                        polygon: [...polygonLines]
                                    });
                                }else {
                                    setLevelData({
                                        ...levelData,
                                        polygonMobile: [...polygonLines]
                                    });
                                }
                            }
                            handleClose();
                        }}>
                        Ok
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.editBuildingPolygon}>
                <div
                    style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + photo})` }}
                    className={buildingPhotoType === 'desktop' ? classes.addLevelPhoto : classes.addLevelPhotoMobile}>
                    <canvas
                        height={photo === 'desktop' ? 643 : 1080}
                        width={photo === 'desktop' ? 1133 : 1080}
                        ref={canvasRef}
                        onClick={handleCanvasClick}
                        className={classes.canvas}
                    >
                    </canvas>
                    <div className={classes.clearCanvasButton}>
                        <Button
                            disableElevation
                            fullWidth
                            variant='contained'
                            color='secondary'
                            onClick={handleClearCanvas}
                        >
                            Resetuj
                        </Button>
                    </div>
                </div>
            </div>

        </Dialog>
    )
}

export default EditLevelPolygonDialog