import { Typography, Button, AppBar, Toolbar, CssBaseline } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminAction } from '../actions/admins_action';
import { useNavigate } from 'react-router-dom';
import useStyles from './style';
// import logo from '../assets/photos/logo.png';
import AddBuildingSection from './AddBuildingSection';
import AddLevelSection from './AddLevelSection';
import AddApartmentSection from './AddApartmentSection';
import EditComplex from './EditComplex';
import AddIcon from '@mui/icons-material/Add';
import EditBuildingLevels from './EditBuildingLevels';
import EditApartments from './EditApartments';
import EditLogoAndPhone from './EditLogoAndPhone';
import { getHeaderDataAction } from '../actions/admins_action';


const SuperAdminPanel = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentSection, setCurrentSection] = React.useState(0);

  const [logo, setLogo] = React.useState('');

  const headerDataSelector = useSelector(state => state.headerDataState);
  const { headerData } = headerDataSelector;

  React.useEffect(() => {
    dispatch(getHeaderDataAction());
  }, []);

  React.useEffect(() => {
    if (typeof headerData !== 'undefined') {
      if (headerData.success) {
        document.querySelector('meta[name="description"]').setAttribute("content", headerData.pageDescription);
        document.title = headerData.pageTitle;
        setLogo(headerData.logo);
      }
    };
  }, [headerData]);

  const handleLogout = () => {
    dispatch(logoutAdminAction());
    navigate('/login');
  };

  const displayCurrentSection = () => {
    switch (currentSection) {
      case 0: return <AddBuildingSection />
      case 1: return <AddLevelSection />
      case 2: return <AddApartmentSection />
      case 3: return <EditComplex setCurrentSection={setCurrentSection} />
      case 4: return <EditBuildingLevels setCurrentSection={setCurrentSection} />
      case 5: return <EditApartments />
      case 6: return <EditLogoAndPhone currentSection={currentSection} />
      default: return <AddBuildingSection />
    };
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        className={classes.appBar}
        elevation={0}
        position="static"
        style={{ backgroundColor: 'white' }}>
        <Toolbar className={classes.toolBar} >
          <div className={classes.logoAndOptions}>
            <img 
              src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`} 
              width={319}
              height={94} 
              alt='Logo' />
            <div className={classes.options}>
              <Typography
                onClick={() => { setCurrentSection(0); }}
                variant='h6'
                className={currentSection === 0 ? `${classes.singleOptionActive} ${classes.singleOption}` : classes.singleOption}>
                <AddIcon fontSize='small' />
                Dodaj zgradu
              </Typography>
              <Typography
                onClick={() => { setCurrentSection(1); }}
                variant='h6'
                className={currentSection === 1 ? `${classes.singleOptionActive} ${classes.singleOption}` : classes.singleOption}>
                <AddIcon fontSize='small' />
                Dodaj sprat
              </Typography>
              <Typography
                onClick={() => { setCurrentSection(2); }}
                variant='h6'
                className={currentSection === 2 ? `${classes.singleOptionActive} ${classes.singleOption}` : classes.singleOption}>
                <AddIcon fontSize='small' />
                Dodaj stan
              </Typography>
              <Typography
                onClick={() => { setCurrentSection(3); }}
                variant='h6'
                className={currentSection === 3 ? `${classes.singleOptionActive} ${classes.singleOption}` : classes.singleOption}>
                Pregled
              </Typography>
              <Typography
                onClick={() => { setCurrentSection(6); }}
                variant='h6'
                className={currentSection === 6 ? `${classes.singleOptionActive} ${classes.singleOption}` : classes.singleOption}>
                Ostalo
              </Typography>
            </div>
          </div>
          <Button
            disableElevation
            size='large'
            variant='contained'
            color='warning'
            onClick={handleLogout}>
            ODJAVA
          </Button>
        </Toolbar>
      </AppBar>

      {displayCurrentSection()}

    </>
  )
}

export default SuperAdminPanel;
