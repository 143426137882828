import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AlertTitle, Alert } from '@mui/material';
import useStyles from './style';
import { getApartmentAction } from '../actions/apartments_action';
import { CircularProgress, Button } from '@mui/material';
import { Typography } from '@material-ui/core';
import noPhoto from '../assets/photos/noPhoto.jpeg';
import EmailIcon from '@mui/icons-material/Email';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import OrbitalIcon from '@mui/icons-material/ViewInAr';
import GalleryIcon from '@mui/icons-material/Collections';
import GalleryDialog from './GalleryDialog';
import SendToEmailDialog from './SendToEmailDialog';
import SendQuestionDialog from './SendQuestionDialog';
import OrbitalViewDialog from './OrbitalViewDialog';
import { GlobalContext } from '../App';
import { useNavigate } from 'react-router-dom';
import compass from '../assets/photos/compass.svg';
import { t } from 'i18next';

const ApartmentDisplay = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const { setCurrentDisplay } = useContext(GlobalContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const apartmentId = searchParams.get('apartmentId');

    const [showGallery, setShowGallery] = React.useState(false);
    const [showSendToEmail, setShowSendToEmail] = React.useState(false);
    const [showSendQuestion, setShowSendQuestion] = React.useState(false);
    const [showOrbitalView, setShowOrbitalView] = React.useState(false);

    const getApartmentSelector = useSelector(state => state.getApartmentState);
    const { loading, apartmentData } = getApartmentSelector;

    React.useEffect(() => {
        if (apartmentId) {
            dispatch(getApartmentAction(apartmentId));
        }
    }, [apartmentId]);

    const ShowError = () => {
        return (
            <Alert severity="error">
                <AlertTitle>Greska</AlertTitle>
                Neuspjesno prikazivanje stana.
            </Alert>
        );
    };

    const downloadPdf = () => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('target', '_blank');
        a.href = process.env.REACT_APP_UPLOADS_PATH + apartmentData.pdfCatalogue;
        a.click();
        a.remove();
    };

    const getNumOfRooms = (type) => {
        switch (type) {
            case 1: return t("Garsonjera");
            case 2: return t("Jednosoban");
            case 3: return t("Dvosoban");
            case 4: return t("Trosoban");
            case 5: return 'Cetvorosoban';
            case 6: return 'Petosoban';
            default: return 0;
        }
    };

    const ShowBuildingAndLevel = () => {
        return (
            <div className={classes.buildingAndLevel} >
                <div className={classes.buildingAndLevelSubDiv}>
                    <Typography variant={isMobile ? 'subtitle1' : 'h4'}>{apartmentData.buildingName}</Typography>
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'}>{t("Zgrada")}</Typography>
                </div>
                <div className={classes.buildingAndLevelSubDiv}>
                    <Typography variant={isMobile ? 'subtitle1' : 'h4'}>{apartmentData.levelNumber}</Typography>
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'}>{t("Sprat")}</Typography>
                </div>
            </div>
        );
    };

    const ShowApartmentDetails = () => {
        return (
            <div className={classes.apartmentDetails}>
                <div className={classes.apartmentDetailsCard}>
                    <Typography variant={isMobile ? 'subtitle1' : 'h5'} >{apartmentData.apartment.number}</Typography>
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'} >{t("Broj")}</Typography>
                </div>
                <div className={classes.apartmentDetailsCard}>
                    <Typography variant={isMobile ? 'subtitle1' : 'h5'} >{t("Tip")}</Typography>
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'} >{getNumOfRooms(apartmentData.apartment.type)}</Typography>
                </div>
                {
                    isMobile ?
                        <div className={classes.apartmentDetailsCard}>
                            <Typography variant={isMobile ? 'subtitle1' : 'h5'} >{apartmentData.levelNumber}</Typography>
                            <Typography variant={isMobile ? 'caption' : 'subtitle1'} >{t("Sprat")}</Typography>
                        </div>
                        :
                        null
                }
                <div className={classes.apartmentDetailsCard}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant={isMobile ? 'subtitle1' : 'h5'} >{+apartmentData.apartment.area}</Typography>
                        <Typography variant={isMobile ? 'subtitle2' : 'h5'}> &#13217;</Typography>
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'} >{t("Površina")}</Typography>
                </div>

                <div className={classes.apartmentDetailsCard}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant={isMobile ? 'subtitle1' : 'h5'} >{+apartmentData.apartment.price}</Typography>
                        <Typography variant={isMobile ? 'subtitle2' : 'h5'}> &euro;</Typography>
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'} >{t("Cijena")}</Typography>
                </div>
                <div className={classes.apartmentDetailsCard}>
                    <img src={compass} width={!isMobile ? '60' : '35'} style={{ marginBottom: '5px' }} />
                    <Typography variant={isMobile ? 'caption' : 'subtitle1'} >
                        {apartmentData.apartment.orientation}
                    </Typography>
                </div>
            </div>
        )
    };


    const ShowApartmentDetailsMobile = () => {
        return (
            isMobile
                ?
                <div className={classes.apartmentDetailsMobile}>
                    <div className={classes.apartmentDetailsCardMobile}>
                        <Typography style={{ fontSize : '24px', fontWeight : '600' }} >{apartmentData.apartment.number}</Typography>
                        <Typography variant='caption' >Broj</Typography>
                    </div>
                    <div className={classes.apartmentDetailsCardMobile}>
                        <Typography style={{ fontSize : '24px', fontWeight : '600' }} >Tip</Typography>
                        <Typography variant='caption' >{getNumOfRooms(apartmentData.apartment.type)}</Typography>
                    </div>

                    <div className={classes.apartmentDetailsCardMobile}>
                        <Typography style={{ fontSize : '24px', fontWeight : '600' }} >{apartmentData.levelNumber}</Typography>
                        <Typography variant='caption'  >Sprat</Typography>
                    </div>

                    <div className={classes.apartmentDetailsCardMobile}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography style={{ fontSize : '24px', fontWeight : '600' }}>{Math.round(+apartmentData.apartment.area)}</Typography>
                            <Typography style={{ fontSize : '24px', fontWeight : '600' }}> &#13217;</Typography>
                        </div>
                        <Typography variant='caption' >Površina</Typography>
                    </div>

                    <div className={classes.apartmentDetailsCardMobile}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography style={{ fontSize : '24px', fontWeight : '600' }}  >{+apartmentData.apartment.price}</Typography>
                            <Typography style={{ fontSize : '24px', fontWeight : '600' }} > &euro;</Typography>
                        </div>
                        <Typography variant='caption' >Cijena</Typography>
                    </div>
                    <div className={classes.apartmentDetailsCardMobile}>
                        <img src={compass} width='40' style={{ paddingBottom : '2px' }} />
                        <Typography variant='caption' >
                            {apartmentData.apartment.orientation.replace(',','/')}
                        </Typography>
                    </div>
                </div>
                :
                null
        )
    };

    const ShowPhotosAndPdf = () => {
        return (
            <div className={classes.photosAndPdf}>
                <div className={classes.oneDiv}>
                    <div className={classes.divCircle} onClick={() => { setShowGallery(true); }}>
                        <GalleryIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Galerija")}</Typography>
                </div>
                <div className={classes.oneDiv}>
                    <div className={classes.divCircle} onClick={() => { setShowOrbitalView(true); }}>
                        <OrbitalIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("3D prikaz")}</Typography>
                </div>
                <div className={classes.oneDiv}>
                    <div className={classes.divCircle} onClick={downloadPdf}>
                        <PdfIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Katalog")}</Typography>
                </div>
                <div className={classes.oneDiv}>
                    <div className={classes.divCircle} onClick={() => { setShowSendToEmail(true); }}>
                        <EmailIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Pošalji na email")}</Typography>
                </div>
                <div className={classes.oneDiv}>
                    <div className={classes.divCircle} onClick={() => { setShowSendQuestion(true); }}>
                        <QuestionMarkIcon fontSize={isMobile ? 'small' : 'medium'} />
                    </div>
                    <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Postavi pitanje")}</Typography>
                </div>
            </div>
        );
    };

    const ShowPhotosAndPdfMobile = () => {
        return (
            isMobile
                ?
                <div className={classes.photosAndPdfMobile}>
                    <div className={classes.oneDiv} onClick={() => { setShowGallery(true); }}>
                        <div className={classes.divCircle}>
                            <GalleryIcon fontSize='large' />
                        </div>
                        <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Galerija")}</Typography>
                    </div>
                    <div className={classes.oneDiv} onClick={() => { setShowOrbitalView(true); }}>
                        <div className={classes.divCircle}>
                            <OrbitalIcon fontSize='large' />
                        </div>
                        <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("3D prikaz")}</Typography>
                    </div>
                    <div className={classes.oneDiv} onClick={downloadPdf}>
                        <div className={classes.divCircle} >
                            <PdfIcon fontSize='large' />
                        </div>
                        <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Katalog")}</Typography>
                    </div>
                    <div className={classes.oneDiv} onClick={() => { setShowSendToEmail(true); }}>
                        <div className={classes.divCircle} >
                            <EmailIcon fontSize='large' />
                        </div>
                        <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Pošalji na email")}</Typography>
                    </div>
                    <div className={classes.oneDiv} onClick={() => { setShowSendQuestion(true); }}>
                        <div className={classes.divCircle} >
                            <QuestionMarkIcon fontSize='large' />
                        </div>
                        <Typography variant={isMobile ? 'caption' : 'subtitle2'}>{t("Postavi pitanje")}</Typography>
                    </div>
                </div>
                :
                null
        );
    };


    const ShowApartmentData = () => {
        return (
            <div
                style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS_PATH + apartmentData.planPhoto || noPhoto})` }}
                className={classes.apartmentDiv}>
                {
                    apartmentData.apartment.status
                        ?
                        <div className={classes.sold}>
                            <Typography variant={isMobile ? 'h3' : 'h1'} color='error'>{t("PRODAT")}</Typography>
                        </div>
                        :
                        null
                }
                <ShowBuildingAndLevel />
                <ShowApartmentDetails />
                <ShowPhotosAndPdf />
                <div className={classes.backDiv}>
                    <Button
                        onClick={() => {
                            navigate(`?buildingId=${apartmentData.apartment.building}&levelId=${apartmentData.apartment.level}`);
                            localStorage.setItem('currentDisplay', JSON.stringify(2));
                            setCurrentDisplay(2);
                        }}
                        style={{ backgroundColor: 'black', color: 'white' }}
                        variant='contained'
                        fullWidth
                        size={isMobile ? 'small' : 'large'}
                        disableElevation>
                        {isMobile ? t("Nazad") : t("Nazad na sprat")}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            {
                loading
                    ?
                    <div className={classes.loading}>
                        <CircularProgress size={200} thickness={5} color='warning' />
                    </div>
                    :
                    null
            }
            {
                typeof apartmentData !== 'undefined'
                    ?
                    apartmentData.success
                        ?
                        [
                            <ShowApartmentData />,
                            <ShowApartmentDetailsMobile />,
                            <ShowPhotosAndPdfMobile/>,
                            <GalleryDialog open={showGallery} setOpen={setShowGallery} gallery={apartmentData.gallery} />,
                            <SendToEmailDialog
                                open={showSendToEmail}
                                setOpen={setShowSendToEmail}
                                apartmentId={apartmentData.apartment.id}
                                buildingName={apartmentData.buildingName}
                                levelNumber={apartmentData.levelNumber} />,
                            <SendQuestionDialog
                                open={showSendQuestion}
                                setOpen={setShowSendQuestion}
                                apartmentId={apartmentData.apartment.id}
                                buildingName={apartmentData.buildingName}
                                levelNumber={apartmentData.levelNumber} />,
                            <OrbitalViewDialog open={showOrbitalView} setOpen={setShowOrbitalView} photo={apartmentData.orbitalView} />
                        ]
                        :
                        <ShowError />
                    :
                    null
            }
        </>
    );
};

export default ApartmentDisplay