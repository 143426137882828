import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import useStyle from './style';
import CloseIcon from '@mui/icons-material/Close';

const EditApartmentGalleryDialog = ({ open, setOpen, apartmentData, setApartmentData, readFileAsBase64 }) => {

    const classes = useStyle();

    const inputRef = React.useRef();

    const handleClose = () => {
        setOpen(false);
    };

    const handleImagesChange = async (e) => {
        if(e.target.files.length > 0){
            const newPhotos = await Promise.all(Array.from(e.target.files).map( async (newPhoto) =>({
                photoId : null,
                photoUrl : await readFileAsBase64(newPhoto),
                photoName : newPhoto.name
            })));
            setApartmentData( prevData => ({
                ...prevData,
                gallery : [...prevData.gallery,...newPhotos]
            }));
        }
    };

    const handleImageRemove = (id, photoName) => {
        if (id) {
            setApartmentData(prevData => ({
                ...prevData,
                galleryPhotosToDelete: [...prevData.galleryPhotosToDelete, id],
                gallery: [...prevData.gallery.filter(photo => photo.photoId !== id)]
            }));
        } else {
            setApartmentData(prevData => ({
                ...prevData,
                gallery: [...prevData.gallery.filter(photo => photo.photoName !== photoName)]
            }));
        }
    };

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                Edit galerije apartmana
            </DialogTitle>

            <DialogContent>

                <div className={classes.editGalleryDiv}>
                    {
                        apartmentData?.gallery.map((photo, index) => {
                            return (
                                <div style={{ width: '300px', position: 'relative' }}>
                                    <img 
                                        style={{ width: '100%' }} 
                                        src={!photo.photoName ? process.env.REACT_APP_UPLOADS_PATH+ photo.photoUrl : photo.photoUrl} 
                                        alt='Apartment Image' 
                                        key={index} />
                                    <IconButton
                                        onClick={() => { handleImageRemove(photo.photoId, photo.photoName); }}
                                        size='medium'
                                        color='error'
                                        style={{ backgroundColor: 'white', position: 'absolute', top: '5px', right: '5px' }} >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            )
                        })
                    }
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Izadji</Button>
                <Button
                    onClick={()=>{ inputRef.current.click(); }}
                    disableElevation
                    variant='contained'
                    color='success'>
                    + Dodaj
                    <input
                        id='dugme23'
                        ref={inputRef}
                        type="file"
                        onChange={handleImagesChange}
                        multiple
                        accept='image/*'
                        hidden
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditApartmentGalleryDialog;