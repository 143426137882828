import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainViewDiv : {
        //border : '4px solid green',
        display : 'grid',
        //gridTemplateColumns : '1fr 3fr',
        height : '100vh',
        [theme.breakpoints.down('960')]: {
            gridTemplateColumns : '1fr',
            height : 'auto'
        }
    }
}));

export default useStyles;