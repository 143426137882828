import React from 'react';
import {
    Typography,
    CssBaseline,
    AppBar,
    Button,
    Toolbar,
    Tooltip,
    LinearProgress,
    InputAdornment,
    TextField,
    IconButton
} from '@mui/material';
import { AlertTitle, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminAction } from '../actions/admins_action';
import { useNavigate } from 'react-router-dom';
import useStyles from './style';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
    getApartmentsForRegularAdminAction,
    changeApartmentStatusAction
} from '../actions/apartments_action';
import { Grid } from '@material-ui/core';
import SearchField from './SearchField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import ChangePriceDialog from './ChangePriceDialog';

const RegularAdminPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const [showPriceDialog, setShowPriceDialog] = React.useState(false);
    const [priceDialogData, setPriceDialogData] = React.useState({});

    const apartmentsSelector = useSelector(state => state.apartmentsRegularAdminState);
    const { loading, apartmentsRegularAdmin: apartmentsData } = apartmentsSelector;

    React.useEffect(() => {
        dispatch(getApartmentsForRegularAdminAction());
    }, []);

    const handleLogout = () => {
        dispatch(logoutAdminAction());
        navigate('/login');
    };

    // const openInNewTab = (link) => {
    //     const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
    //     if (newWindow) newWindow.opener = null;
    // };

    const ShowLoading = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Typography variant='caption'>Ucitavanje...</Typography>
                <LinearProgress color='success' />
            </div>
        )
    };

    const ShowError = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <Alert severity="error">
                    <AlertTitle>Greška</AlertTitle>
                    Neuspješno prikazivanje stanova
                </Alert>
            </div>
        )
    };

    return (
        <>
            <CssBaseline />
            <AppBar
                className={classes.appBar}
                elevation={2}
                position="static"
                style={{ backgroundColor: 'white' }}>
                <Toolbar className={classes.toolBar} >
                    <img
                        src={`${process.env.REACT_APP_UPLOADS_PATH}/logo/logo.png`}
                        width={319}
                        height={94}
                        alt='Logo' />
                    <Button
                        className={classes.logout}
                        disableElevation
                        size='large'
                        variant='contained'
                        color='warning'
                        onClick={handleLogout}>
                        ODJAVA
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.searchFieldDiv}>
                <SearchField apartmentsData={apartmentsData} dispatch={dispatch} />
            </div>
            <div className={classes.tableDiv}>
                {
                    loading
                        ?
                        <ShowLoading />
                        :
                        null
                }
                {
                    typeof apartmentsData !== 'undefined'
                        ?
                        apartmentsData.success
                            ?
                            <TableContainer component={Paper} elevation={1} style={{ borderRadius: '0px' }}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow >

                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '21px' }}>Broj</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '21px' }}>Sprat</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '21px' }}>Površina</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '21px' }}>Cijena</TableCell>
                                            <TableCell align='center' style={{ fontWeight: 'bold', fontSize: isMobile ? '15px' : '21px' }}>Status</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apartmentsData.apartments.map((row, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.number}</TableCell>
                                                <TableCell>{row.level_number}.</TableCell>
                                                <TableCell>{+row.area} &#13217;</TableCell>
                                                <TableCell style={{
                                                    fontSize: isMobile ? '14px' : '18px',
                                                    padding: '5px'
                                                }} >
                                                    {+row.price} &euro;
                                                    <Button
                                                        className={classes.editPrice}
                                                        style={{
                                                            minWidth: isMobile ? '20px' : '50px',
                                                            marginLeft: isMobile ? '5px' : '10px'
                                                        }}
                                                        variant='contained'
                                                        size='small'
                                                        color='success'
                                                        disableElevation
                                                        onClick={() => {
                                                            setPriceDialogData({ ...row });
                                                            setShowPriceDialog(true);
                                                        }}>
                                                        <EditIcon
                                                            style={{ color: 'white', fontSize: isMobile ?  '11px' : '15px' }}
                                                            fontSize='small' />
                                                    </Button>

                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        !row.status
                                                            ?
                                                            <Button
                                                                style={{
                                                                    minWidth: isMobile ? '10px' : '50px',
                                                                }}
                                                                size={isMobile ? 'small' : 'medium'}
                                                                onClick={() => { dispatch(changeApartmentStatusAction({ apartmentId: row.id })) }}
                                                                fullWidth
                                                                disableElevation
                                                                variant='contained'
                                                                color='success'>
                                                                Slobodan
                                                            </Button>
                                                            :
                                                            <Button
                                                                size={isMobile ? 'small' : 'medium'}
                                                                onClick={() => { dispatch(changeApartmentStatusAction({ apartmentId: row.id })) }}
                                                                fullWidth
                                                                disableElevation
                                                                variant='contained'
                                                                color='warning'>
                                                                Prodat
                                                            </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <ShowError />
                        :
                        null
                }
            </div>
            {
                showPriceDialog
                    ?
                    <ChangePriceDialog
                        dispatch={dispatch}
                        open={showPriceDialog}
                        setOpen={setShowPriceDialog}
                        info={priceDialogData}
                        setInfo={setPriceDialogData}
                        apartmentsData={apartmentsData} />
                    :
                    null
            }
        </>
    )
}

export default RegularAdminPanel