
export const addNewApartmentReducer = (newApartmentData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_ADD_NEW_APARTMENT':
            return {
                loading: true
            }
        case 'SUCCESS_ADD_NEW_APARTMENT':
        case 'ERROR_ADD_NEW_APARTMENT':
            return {
                loading: false,
                newApartmentData: action.payload
            }
        default: return newApartmentData;
    }
};

export const getApartmentsForLevelReducer = (apartmentsForLevel = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_APARTMENTS_FOR_LEVEL':
            return {
                loading: true
            }
        case 'SUCCESS_GET_APARTMENTS_FOR_LEVEL':
        case 'ERROR_GET_APARTMENTS_FOR_LEVEL':
            return {
                loading: false,
                apartmentsForLevel: action.payload
            }
        default: return apartmentsForLevel;
    }
};

export const getApartmentReducer = (apartmentData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_APARTMENT':
            return {
                loading: true
            }
        case 'SUCCESS_GET_APARTMENT':
        case 'ERROR_GET_APARTMENT':
            return {
                loading: false,
                apartmentData: action.payload
            }
        default: return apartmentData;
    }
};

export const sendPlanToEmailReducer = (sendPlanToEmail = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SEND_PLAN_TO_EMAIL':
            return {
                loading: true
            }
        case 'SUCCESS_SEND_PLAN_TO_EMAIL':
        case 'ERROR_SEND_PLAN_TO_EMAIL':
            return {
                loading: false,
                sendPlanToEmail: action.payload
            }
        default: return sendPlanToEmail;
    }
};

export const sendQuestionReducer = (sendQuestion = {}, action) => {
    switch (action.type) {
        case 'REQUEST_SEND_QUESTION':
            return {
                loading: true
            }
        case 'SUCCESS_SEND_QUESTION':
        case 'ERROR_SEND_QUESTION':
            return {
                loading: false,
                sendQuestion: action.payload
            }
        default: return sendQuestion;
    }
};

export const getParametersReducer = (parameters = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_PARAMETERS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_PARAMETERS':
        case 'ERROR_GET_PARAMETERS':
            return {
                loading: false,
                parameters: action.payload
            }
        default: return parameters;
    }
};

export const getApartmentsForRegularAdminReducer = (apartmentsRegularAdmin = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_APARTMENTS_FOR_REGULAR_ADMIN':
            return {
                loading: true
            }
        case 'SUCCESS_GET_APARTMENTS_FOR_REGULAR_ADMIN':
        case 'ERROR_GET_APARTMENTS_FOR_REGULAR_ADMIN':
            return {
                loading: false,
                apartmentsRegularAdmin: action.payload
            }
        default: return apartmentsRegularAdmin;
    }
};

//REQUEST_GET_TABLE_APARTMENTS

export const getTableApartmentsReducer = (tableApartmentsData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_TABLE_APARTMENTS':
            return {
                loading: true
            }
        case 'SUCCESS_GET_TABLE_APARTMENTS':
        case 'ERROR_GET_TABLE_APARTMENTS':
            return {
                loading: false,
                tableApartmentsData: action.payload
            }
        default: return tableApartmentsData;
    }
};

export const getApartmentToEditReducer = (apartmentToEditData = {}, action) => {
    switch (action.type) {
        case 'REQUEST_GET_APARTMENT_TO_EDIT':
            return {
                loading_apt_to_edit: true
            }
        case 'SUCCESS_GET_APARTMENT_TO_EDIT':
        case 'ERROR_GET_APARTMENT_TO_EDIT':
            return {
                loading_apt_to_edit: false,
                apartmentToEditData: action.payload
            }
        default: return apartmentToEditData;
    }
};